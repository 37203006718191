import { Tooltip } from "antd"
import { formatTokenAmount } from "../../utils/tokenUtils"
import { GetTokenSummaryResponse } from "../../../client"
import { formatPercent } from "../../utils/utils"

export const PremintPctBadge = ({
  token,
  precision = 2,
}: {
  token: GetTokenSummaryResponse
  precision?: number
}) => {
  const ticker = token.ticker || ""
  const { preMint, decimal, maxSupply } = token
  const preMintedPct = (preMint / maxSupply) * 100

  const preMintedBadgeType = (premintedPct: number) => {
    if (premintedPct === 0) {
      return "light-success"
    } else {
      return "secondary"
    }
  }

  return (
    <Tooltip title={formatTokenAmount(ticker, preMint, decimal)}>
      <span className={`badge badge-${preMintedBadgeType(preMintedPct)}`}>
        {formatPercent(preMintedPct, precision)}
      </span>
    </Tooltip>
  )
}
