import { Spin } from "antd"

export const ChartLoading = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center bg-light"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Spin size="large" />
    </div>
  )
}
