/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState } from "react"
import { SearchBar } from "./SearchBar"

const Toolbar = () => {
  const [searchBarWidth, setSearchBarWidth] = useState(0)
  return (
    <div className="d-flex justify-content-center px-5">
      <div
        className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-3 h-50px my-12 w-100 w-xl-50"
        ref={(el) => {
          if (el) {
            const resizeObserver = new ResizeObserver((entries) => {
              for (let entry of entries) {
                setSearchBarWidth(entry.contentRect.width)
              }
            })
            resizeObserver.observe(el)
          }
        }}
      >
        <div className="row flex-grow-1 mb-lg-0 ">
          <SearchBar width={searchBarWidth} />
        </div>
      </div>
    </div>
  )
}

export { Toolbar }
