import { TokenTransactionResponse } from "../../../client"
import { IntlShape } from "react-intl"
export interface TokenTransactionType {
  type: string
  iconClass: string
  iconPath: string
}

export const getTokenTrasactionType = (
  tx: TokenTransactionResponse,
  address: string,
  intl: IntlShape
): TokenTransactionType => {
  switch (tx.operation) {
    case "MINT":
      return {
        type: intl.formatMessage({ id: "TOKEN.TRANSACTION.TYPE.MINT" }),
        iconClass: "svg-icon-primary",
        iconPath: "/media/icons/duotune/general/gen041.svg",
      }
    case "TRANSFER":
      if (tx.toAddress === address) {
        return {
          type: intl.formatMessage({ id: "TOKEN.TRANSACTION.TYPE.RECEIVED" }),
          iconClass: "svg-icon-primary",
          iconPath: "/media/icons/duotune/general/gen041.svg",
        }
      } else if (tx.fromAddress === address) {
        return {
          type: intl.formatMessage({ id: "TOKEN.TRANSACTION.TYPE.SENT" }),
          iconClass: "svg-icon-danger",
          iconPath: "/media/icons/duotune/general/gen042.svg",
        }
      } else {
        return {
          type: intl.formatMessage({ id: "TOKEN.TRANSACTION.TYPE.TRANSFER" }),
          iconClass: "svg-icon-primary",
          iconPath: "/media/icons/duotune/arrows/arr001.svg",
        }
      }
    case "DEPLOY":
      return {
        type: intl.formatMessage({ id: "TOKEN.TRANSACTION.TYPE.DEPLOY" }),
        iconClass: "svg-icon-primary",
        iconPath: "/media/icons/duotune/coding/cod002.svg",
      }
    default:
      return {
        type: intl.formatMessage({ id: "TOKEN.TRANSACTION.TYPE.UNKNOWN" }),
        iconClass: "svg-icon-warning",
        iconPath: "",
      }
  }
}
