import { useLocalStorageState, useRequest } from "ahooks"
import { FC, createContext, useContext, ReactNode } from "react"
import { io, Socket } from "socket.io-client"
import {
  MarketDataService,
  MarketDataResponse,
  NetworkDataService,
  NetworkInfoResponse,
} from "../../client/"

import {
  krc20PriceUnitKey,
  showAddressDistAbsoluteDiffKey,
  showTxnDetailKey,
} from "../constants/localStorageKeys"

const kaspadSocket = io(process.env.REACT_APP_WS_SERVER || "", {
  path: "/ws/socket.io",
  withCredentials: true,
  transports: ["websocket"],
})

export type WithChildren = {
  children?: ReactNode
}

export interface GlobalDataContextModel {
  marketData: MarketDataResponse | undefined
  networkData: NetworkInfoResponse | undefined

  showTxnDetail: boolean
  toggleShowTxnDetail: () => void
  showAddressDistAbsoluteDiff: boolean
  toggleShowAddressDistAbsoluteDiff: () => void
  krc20PriceUnit: "KAS" | "USD" | undefined
  toggleKrc20PriceUnit: () => void

  kaspadSocket: Socket
}

const GlobalDataContext = createContext<GlobalDataContextModel>({
  marketData: undefined,
  networkData: undefined,
  showTxnDetail: false,
  toggleShowTxnDetail: () => {},
  showAddressDistAbsoluteDiff: false,
  toggleShowAddressDistAbsoluteDiff: () => {},
  krc20PriceUnit: undefined,
  toggleKrc20PriceUnit: () => {},

  kaspadSocket,
})

const GlobalDataProvider: FC<WithChildren> = ({ children }) => {
  const { data: marketData } = useRequest(
    MarketDataService.marketControllerGetMarketData
  )

  const { data: networkData } = useRequest(
    NetworkDataService.networkControllerGetNetworkInfo
  )

  const [showTxnDetail, setShowTxnDetail] = useLocalStorageState<boolean>(
    showTxnDetailKey,
    {
      defaultValue: false,
    }
  )

  const [showAddressDistAbsoluteDiff, setShowAddressDistAbsoluteDiff] =
    useLocalStorageState<boolean>(showAddressDistAbsoluteDiffKey, {
      defaultValue: true,
    })

  const [krc20PriceUnit, setKrc20PriceUnit] = useLocalStorageState<
    "KAS" | "USD"
  >(krc20PriceUnitKey, {
    defaultValue: "USD",
  })

  const toggleShowTxnDetail = () => {
    setShowTxnDetail(!showTxnDetail)
  }

  const toggleShowAddressDistAbsoluteDiff = () => {
    setShowAddressDistAbsoluteDiff(!showAddressDistAbsoluteDiff)
  }

  const toggleKrc20PriceUnit = () => {
    setKrc20PriceUnit(krc20PriceUnit === "KAS" ? "USD" : "KAS")
  }

  const value: GlobalDataContextModel = {
    marketData,
    networkData,
    showTxnDetail: showTxnDetail ?? false,
    toggleShowTxnDetail,
    showAddressDistAbsoluteDiff: showAddressDistAbsoluteDiff ?? false,
    toggleShowAddressDistAbsoluteDiff,
    krc20PriceUnit,
    toggleKrc20PriceUnit,
    kaspadSocket,
  }

  return (
    <GlobalDataContext.Provider value={value}>
      {children}
    </GlobalDataContext.Provider>
  )
}

export { GlobalDataContext, GlobalDataProvider }

export function useGlobalData() {
  return useContext(GlobalDataContext)
}
