/* eslint-disable react/jsx-no-target-blank */
import { KTSVG } from "../../_metronic/helpers"
import { PasteIcon } from "./PasteIcon"

export const MonitorAddressModal = ({ address }: { address: string }) => {
  const qrCodeData = `https://t.me/kaspa_tx_notifier_bot?start=${address.slice(
    6
  )}`

  return (
    <div className="modal fade" tabIndex={-1} id="monitor-address-modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Watch This Address</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          <div className="modal-body d-flex flex-column align-items-center">
            <img
              src={` https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${qrCodeData}`}
              alt=""
            />
          </div>

          <p className="fs-6 fw-bold text-center">
            <a href={qrCodeData} target="_blank">
              Click here
            </a>{" "}
            if you have Telegram installed on this device
          </p>
          <div className="accordion" id="kt_accordion_1">
            <div className="accordion-item">
              <h2 className="accordion-header" id="kt_accordion_1_header_1">
                <button
                  className="accordion-button fs-4 fw-bold collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_accordion_1_body_1"
                  aria-expanded="false"
                  aria-controls="kt_accordion_1_body_1"
                >
                  Step 1: Download Telegram (If you haven't)
                </button>
              </h2>
              <div
                id="kt_accordion_1_body_1"
                className="accordion-collapse collapse"
                aria-labelledby="kt_accordion_1_header_1"
                data-bs-parent="#kt_accordion_1"
              >
                <div className="accordion-body">
                  You can download Telegram{" "}
                  <a href="https://telegram.org" target="_blank">
                    here
                  </a>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="kt_accordion_1_header_2">
                <button
                  className="accordion-button fs-4 fw-bold collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_accordion_1_body_2"
                  type="button"
                >
                  Step 2: Scan the QR code
                </button>
              </h2>
              <div
                id="kt_accordion_1_body_2"
                className="accordion-collapse collapse"
                aria-labelledby="kt_accordion_1_header_2"
                data-bs-parent="#kt_accordion_1"
              >
                <div className="accordion-body">
                  You will be redirected to the "Kaspa Watch" Telegram bot
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="kt_accordion_1_header_3">
                <button
                  className="accordion-button fs-4 fw-bold collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_accordion_1_body_3"
                >
                  Step 3: Press "Start" button
                </button>
              </h2>
              <div
                id="kt_accordion_1_body_3"
                className="accordion-collapse collapse"
                aria-labelledby="kt_accordion_1_header_3"
                data-bs-parent="#kt_accordion_1"
              >
                <div className="accordion-body">
                  Once you are in the chat screen with "Kaspa Watch", simply
                  press the "Start" button at the bottom of the screen to begin
                  receiving notificaiton for all incoming transactions to the
                  address.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
