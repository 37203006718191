/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import { Link } from "react-router-dom"
import { useLocation } from "react-router"
import clsx from "clsx"
import { checkIsActive, KTSVG } from "../../../helpers"

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
  newWindow?: boolean
  modalTarget?: string
}

const MenuItem: React.FC<Props> = ({
  to,
  title,
  icon,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
  newWindow = false,
  modalTarget,
}) => {
  const { pathname } = useLocation()
  const modalProps = modalTarget && {
    "data-bs-toggle": "modal",
    "data-bs-target": modalTarget,
  }

  const children = (
    <>
      {hasBullet && (
        <span className="menu-bullet">
          <span className="bullet bullet-dot"></span>
        </span>
      )}
      {icon && (
        <span className="menu-icon">
          <KTSVG path={icon} className="svg-icon-2" />
        </span>
      )}
      {fontIcon && (
        <span className="menu-icon">
          <i className={clsx("bi fs-3", fontIcon)}></i>
        </span>
      )}
      <span className="menu-title">{title}</span>
      {hasArrow && <span className="menu-arrow"></span>}
    </>
  )

  // const linkTag = (
  //   <Link
  //     className={clsx("menu-link py-3", {
  //       active: checkIsActive(pathname, to),
  //     })}
  //     to={to}
  //     {...modalProps}
  //   >
  //     {children}
  //   </Link>
  // )

  const aTag = (
    <a
      className={clsx("menu-link py-3", {
        active: checkIsActive(pathname, to),
      })}
      href={to}
      target={newWindow ? "_blank" : ""}
      {...modalProps}
    >
      {children}
    </a>
  )

  // Have to use <a> to hard refresh page so socket reconnects
  // const renderedLink = to === "/" ? aTag : linkTag
  return <div className="menu-item me-lg-1">{aTag}</div>
}

export { MenuItem }
