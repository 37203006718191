/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import { Alert, Table, Tabs, TabsProps, Tooltip } from "antd"
import Search from "antd/es/input/Search"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { GetTokenSummaryResponse, TokenService } from "../../../client"
import { Card } from "../../components/Card"
import { getTokenRoute, getTransactionRoute } from "../../hooks/navigator"
import { ColumnsType } from "antd/es/table"
import {
  formatTokenAmount,
  trackTokenAdsClickedEvent,
} from "../../utils/tokenUtils"
import { KSPR_AFFILIATE_URL, UNIT_STRING } from "../../constants/constants"
import { AdsAlertBody } from "../../components/partials/AdsAlert"
import {
  formatCash,
  formatUnixTime,
  getTimeDifference,
} from "../../utils/utils"
import { FiatAmount } from "../../components/FiatAmountBadge"
import { TokenIcon } from "../../components/partials/TokenIcon"
import { PriceDiffBadge } from "../../components/PriceDiffBadge"
import { PremintPctBadge } from "../../components/partials/PremintPctBadge"
import { TokenMintedProgressiveBar } from "../../components/partials/TokenMintedProgressiveBar"
import { MiniPriceChart } from "../../components/charts/MiniPriceChart"
import { TransactionViewType } from "../address/components/AddressTransactions"
import { TokenTradingVolume } from "../../components/partials/TokenTradingVolume"
import { Link, useLocation } from "react-router-dom"
import { useGlobalData } from "../../utils/GlobalDataProvider"
import { Krc20PriceUnitToggleButton } from "../../components/partials/Krc20PriceUnitToggleButton"
import { TopActivityCard } from "../token/components/TopActivityCard"
import { useRequest } from "ahooks"
import { LowLiquidityWarningIcon } from "../../components/partials/LowLiquidityWarningIcon"

export enum TokenListViewType {
  TokenList = "token_list",
  Trending = "trending",
}

const Krc20TokenList = ({ tokens }: { tokens?: GetTokenSummaryResponse[] }) => {
  const intl = useIntl()

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const requestedViewType = searchParams.get("view") as TokenListViewType
  const [viewType, setViewType] = useState<TokenListViewType>(
    requestedViewType || TokenListViewType.TokenList
  )

  const [tableData, setTableData] = useState<GetTokenSummaryResponse[]>()
  const { marketData, krc20PriceUnit } = useGlobalData()

  const columns: ColumnsType<GetTokenSummaryResponse> = [
    {
      title: intl.formatMessage({ id: "TICKER" }),
      fixed: "left",
      render: (_, record: GetTokenSummaryResponse) => {
        const { ticker } = record

        return (
          <Link
            className="fw-bold d-flex align-items-center text-body text-hover-primary"
            to={getTokenRoute(ticker)}
          >
            <TokenIcon url={record.iconUrl} className="me-3" size="25px" />
            {ticker}
          </Link>
        )
      },
      sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) =>
        a.ticker.localeCompare(b.ticker),
    },
    {
      title: intl.formatMessage({
        id: krc20PriceUnit === "USD" ? "PRICE_IN_USD" : "PRICE_IN_KAS",
      }),
      render: (_, record: GetTokenSummaryResponse) => {
        const { price } = record
        const { floorPrice = 0 } = price || {}

        const displayPrice =
          krc20PriceUnit === "KAS"
            ? `${floorPrice.toFixed(8)} ${UNIT_STRING}`
            : `$${(floorPrice * (marketData?.price || 0)).toLocaleString(
                undefined,
                {
                  maximumFractionDigits: 8,
                }
              )}`

        return (
          <Link
            to={getTokenRoute(
              record.ticker,
              TransactionViewType.TokenPriceChart
            )}
            className="d-flex align-items-center text-body text-hover-primary"
          >
            <span className="me-2">{price ? displayPrice : "-"}</span>

            {price?.change24h ? (
              <PriceDiffBadge diff={price.change24h} precision={0} />
            ) : null}
          </Link>
        )
      },
      sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) =>
        (a.price?.floorPrice || 0) - (b.price?.floorPrice || 0),
      filters: [
        {
          text: intl.formatMessage({ id: "HAS_PRICE" }),
          value: true,
        },
        {
          text: intl.formatMessage({ id: "NO_PRICE" }),
          value: false,
        },
      ],
      onFilter: (value, record) => {
        if (value === true) {
          return record.price !== undefined && record.price.floorPrice > 0
        }

        return record.price?.floorPrice === 0 || !record.price
      },
    },

    {
      title: intl.formatMessage({ id: "LAST_7_DAYS" }),
      width: 140,
      render: (_, record: GetTokenSummaryResponse) => {
        const { priceHistory, price } = record

        return (
          <Link
            to={getTokenRoute(
              record.ticker,
              TransactionViewType.TokenPriceChart
            )}
          >
            <MiniPriceChart
              data={priceHistory?.map((p) => p.p) || []}
              color={
                price?.change24h && price.change24h > 0 ? "#47BE7D" : "#F1416C"
              }
            />
          </Link>
        )
      },
    },
    {
      title: intl.formatMessage({ id: "DASHBOARD.MARKET.MARKET_CAP.TITLE" }),
      render: (_, record: GetTokenSummaryResponse) => {
        const { price, totalMinted, decimal, tradeVolume } = record
        const marketCapInKas =
          (price?.floorPrice || 0) * (totalMinted / 10 ** decimal)

        return (
          <Link
            to={getTokenRoute(
              record.ticker,
              TransactionViewType.TokenPriceChart
            )}
            className="text-body text-hover-primary"
          >
            {price?.floorPrice ? (
              <div>
                {FiatAmount({
                  kaspaAmount: marketCapInKas,
                  precision: 0,
                })}
                {tradeVolume?.amountInUsd === 0 && <LowLiquidityWarningIcon />}
              </div>
            ) : (
              <div>-</div>
            )}
          </Link>
        )
      },
      sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) => {
        const aMarketCapInKas =
          (a.price?.floorPrice || 0) * (a.totalMinted / 10 ** a.decimal)
        const bMarketCapInKas =
          (b.price?.floorPrice || 0) * (b.totalMinted / 10 ** b.decimal)

        return aMarketCapInKas - bMarketCapInKas
      },
    },
    {
      title: intl.formatMessage({ id: "TRADING_VOLUME_24H" }),
      filters: [
        {
          text: intl.formatMessage({ id: "NON_ZERO_TRADING_VOLUME" }),
          value: true, // indicate the filter is applied
        },
      ],
      onFilter: (value, record) => {
        if (value) {
          const { tradeVolume } = record
          return tradeVolume?.amountInUsd > 0
        }

        return true
      },
      render: (_, record: GetTokenSummaryResponse) => {
        const { tradeVolume } = record

        return (
          <Link
            to={getTokenRoute(
              record.ticker,
              TransactionViewType.TokenPriceChart
            )}
            className="text-body text-hover-primary"
          >
            {tradeVolume?.amountInUsd ? (
              <TokenTradingVolume tradeVolume={tradeVolume} />
            ) : (
              "-"
            )}
          </Link>
        )
      },
      sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) =>
        a?.tradeVolume?.amountInUsd - b?.tradeVolume?.amountInUsd,
    },
    {
      title: intl.formatMessage({ id: "AGE" }),
      render: (_, record: GetTokenSummaryResponse) => {
        const { deployedAt } = record
        return (
          <Tooltip title={formatUnixTime(deployedAt / 1000)}>
            <Link
              to={getTransactionRoute(record.revealHash)}
              className="text-body text-hover-primary"
            >
              {getTimeDifference(deployedAt, true)}
            </Link>
          </Tooltip>
        )
      },
      sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) =>
        a.deployedAt - b.deployedAt,
    },

    {
      title: intl.formatMessage({ id: "TOKEN.HEADER.PRE_MINT" }),
      render: (_, record: GetTokenSummaryResponse) => {
        return <PremintPctBadge token={record} precision={0} />
      },
      sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) => {
        const aPreMintedPct = (a.preMint / a.maxSupply) * 100
        const bPreMintedPct = (b.preMint / b.maxSupply) * 100
        return aPreMintedPct - bPreMintedPct
      },
      filters: [
        {
          text: intl.formatMessage({ id: "FAIR_LAUNCH" }),
          value: 0,
        },
        {
          text: intl.formatMessage({ id: "LESS_THAN_10_PCT_PRE_MINT" }),
          value: 10,
        },
        {
          text: intl.formatMessage({ id: "LESS_THAN_50_PCT_PRE_MINT" }),
          value: 50,
        },
        {
          text: intl.formatMessage({ id: "LESS_THAN_100_PCT_PRE_MINT" }),
          value: 100,
        },
      ],
      onFilter: (value, record) => {
        const valueNumber = Number(value)
        const preMintedPct = (record.preMint / record.maxSupply) * 100

        if (valueNumber === 100) {
          return preMintedPct === valueNumber
        }

        return preMintedPct <= valueNumber
      },
    },
    {
      title: intl.formatMessage({ id: "MINTED" }),
      render: (_, record: GetTokenSummaryResponse) => {
        return <TokenMintedProgressiveBar token={record} />
      },
      sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) => {
        const aCurrentSupplyPct = (a.totalMinted / a.maxSupply) * 100
        const bCurrentSupplyPct = (b.totalMinted / b.maxSupply) * 100
        return aCurrentSupplyPct - bCurrentSupplyPct
      },
      filters: [
        {
          text: intl.formatMessage({ id: "ZERO_MINTED" }),
          value: 0,
        },
        {
          text: intl.formatMessage({ id: "LESS_THAN_10_PCT_MINTED" }),
          value: 10,
        },
        {
          text: intl.formatMessage({ id: "LESS_THAN_50_PCT_MINTED" }),
          value: 50,
        },
        {
          text: intl.formatMessage({ id: "LESS_THAN_100_PCT_MINTED" }),
          value: -1,
        },
        {
          text: intl.formatMessage({ id: "FULLY_MINTED" }),
          value: 100,
        },
      ],
      onFilter: (value, record) => {
        const valueNumber = Number(value)
        const currentSupplyPct = (record.totalMinted / record.maxSupply) * 100

        if (valueNumber === 100) {
          return currentSupplyPct === valueNumber
        }

        if (valueNumber === -1) {
          return currentSupplyPct < 100
        }

        return currentSupplyPct <= valueNumber
      },
    },
    {
      title: intl.formatMessage({ id: "TOTAL_SUPPLY" }),
      render: (_, record: GetTokenSummaryResponse) => {
        const ticker = record.ticker || ""
        const { decimal } = record
        return (
          <Tooltip title={formatTokenAmount(ticker, record.maxSupply, decimal)}>
            {formatCash(record.maxSupply / Math.pow(10, decimal))}
          </Tooltip>
        )
      },
      sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) =>
        a.maxSupply - b.maxSupply,
    },
    {
      title: intl.formatMessage({ id: "MINT_COUNT" }),
      render: (_, record: GetTokenSummaryResponse) => {
        const { mintTotal } = record

        return (
          <div className="d-flex align-items-center">
            {mintTotal?.toLocaleString()}
          </div>
        )
      },
      sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) =>
        a.mintTotal - b.mintTotal,
    },
    {
      title: intl.formatMessage({ id: "HOLDER_COUNT" }),
      render: (_, record: GetTokenSummaryResponse) => {
        const { holderTotal } = record

        return (
          <div className="d-flex align-items-center">
            {holderTotal?.toLocaleString()}
          </div>
        )
      },
      sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) =>
        a.holderTotal - b.holderTotal,
    },
  ]

  const onTableSearch = (val: string) => {
    if (val) {
      setTableData(
        tokens?.filter((row) =>
          row.ticker.toLowerCase().includes(val.toLowerCase())
        )
      )
    } else {
      setTableData(tokens)
    }
  }

  useEffect(() => {
    setTableData(tokens)
  }, [tokens])

  const { data: trendingData, loading: trendingLoading } = useRequest(
    async () => TokenService.tokenControllerGetKrc20Trending()
  )

  const DashboardCard = () => {
    return (
      <div>
        <Table
          size="middle"
          loading={!tableData}
          columns={columns}
          dataSource={tableData}
          scroll={{ x: "max-content" }}
          pagination={{
            defaultPageSize: 20,
            showTotal: (total) =>
              intl.formatMessage(
                { id: "TOTAL_X_TOKENS" },
                {
                  total,
                }
              ),
          }}
        />
      </div>
    )
  }

  const TrendingCard = () => {
    return (
      <div className="row mt-4">
        <div className="col-xl-6 mb-4">
          <TopActivityCard
            className="bg-body pt-4 p-xl-4 border border-gray-300 border-dashed rounded h-100"
            overview={trendingData?.mostMinted}
            activityType="mint"
            loading={trendingLoading}
          />
        </div>
        <div className="col-xl-6 mb-6">
          <TopActivityCard
            className="bg-body pt-4 p-xl-4 border border-gray-300 border-dashed rounded h-100"
            overview={trendingData?.mostTransferred}
            activityType="transfer"
            loading={trendingLoading}
          />
        </div>
      </div>
    )
  }

  const items: TabsProps["items"] = [
    {
      key: TokenListViewType.TokenList,
      label: intl.formatMessage({ id: "TOKEN.HEADER.TOKEN_LIST.TITLE_1" }),
      children: <DashboardCard />,
      icon: <i className="bi bi-list-ul fs-4"></i>,
    },
    {
      key: TokenListViewType.Trending,
      label: intl.formatMessage({ id: "TOKEN.HEADER.TOKEN_LIST.TITLE_2" }),
      children: <TrendingCard />,
      icon: <i className="bi bi-fire fs-4"></i>,
    },
  ]

  return (
    <div className="col-xl-12" id="top-addresses">
      <Card
        className="bg-body"
        title={intl.formatMessage({
          id: `TOKEN.HEADER.TOKEN_LIST.TITLE`,
        })}
        description={intl.formatMessage({
          id: `TOKEN.HEADER.TOKEN_LIST.DESCRIPTION`,
        })}
        toolbar={
          <div className="d-flex align-items-center">
            <Search
              placeholder={intl.formatMessage({
                id: "SEARCH_FOR_TOKEN_TICKER",
              })}
              allowClear
              onChange={(e) => onTableSearch(e.target.value)}
            />
            <span className="ms-4">
              <Krc20PriceUnitToggleButton />
            </span>
          </div>
        }
        body={
          <Tabs
            defaultActiveKey={viewType}
            items={items}
            destroyInactiveTabPane
            onChange={(key) => {
              setViewType(key as TokenListViewType)
              searchParams.set("view", key as string)
              const newUrl = `${
                window.location.pathname
              }?${searchParams.toString()}`
              window.history.pushState(null, "", newUrl)
            }}
          />
        }
      />
    </div>
  )
}

export { Krc20TokenList }
