/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx"
import { KTSVG } from "../../../../_metronic/helpers"
import CountUp from "react-countup"
import { Skeleton } from "antd"

type Props = {
  className?: string
  svgIcon?: string
  titleClass?: string
  descriptionClass?: string
  iconClass?: string
  title?: string | JSX.Element | number
  titleSuffix?: string
  description?: string
  decimals?: number
}

const MetricCard = (props: Props) => {
  const {
    className,
    svgIcon,
    titleClass,
    descriptionClass,
    iconClass,
    description,
  } = props

  const getCardValue = ({ title, titleSuffix, decimals }: Props) => {
    if (typeof title === "number") {
      return (
        <CountUp
          preserveValue
          decimals={decimals}
          end={title}
          suffix={titleSuffix ? ` ${titleSuffix}` : ""}
        />
      )
    } else if (title) {
      return (
        <>
          {title} {titleSuffix}
        </>
      )
    } else {
      return <Skeleton.Button active size={"small"} />
    }
  }

  return (
    <div className={clsx("card", className)}>
      <div className="card-body d-flex flex-column justify-content-between">
        <KTSVG
          path={svgIcon || ""}
          className={clsx(iconClass, "svg-icon-2hx ms-n1 flex-grow-1")}
        />
        <div className="d-flex flex-column">
          <div className={clsx(titleClass, "fw-bold fs-3 mb-0 mt-5")}>
            {getCardValue(props)}
          </div>
          <div className={clsx(descriptionClass, "fw-semibold fs-6")}>
            {description}
          </div>
        </div>
      </div>
    </div>
  )
}

export { MetricCard }
