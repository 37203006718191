import CountUp from "react-countup"
import { EstimatedFeeResponse } from "../../../client"
import { sompiToKaspa } from "../../utils/utils"
import { UNIT_STRING } from "../../constants/constants"
import { useIntl } from "react-intl"

const STANDARD_TX_MASS = 2036

function roundUp(num: number, decimalPlaces = 0) {
  const multiplier = Math.pow(10, decimalPlaces)
  return Math.ceil(num * multiplier) / multiplier
}

const BucketRow = ({
  title,
  formattedFee,
}: {
  title: string
  formattedFee: React.ReactNode
}) => {
  return (
    <div className="menu-item px-4 my-1 d-flex align-items-center">
      <span className="bullet bullet-dot bg-primary me-2"></span>
      <span className="menu-title">
        {title}: {formattedFee}
      </span>
    </div>
  )
}

const GasWidget = ({
  estimatedFee,
}: {
  estimatedFee?: EstimatedFeeResponse
}) => {
  const intl = useIntl()
  const formatGasAmount = (sompi?: number) => {
    if (!sompi) {
      return ""
    }

    const CONVERSION_THRESHOLD = 0.01 // convert to KAS if fee is greater than 0.01 KAS
    const feeAmountInSompi = sompi * STANDARD_TX_MASS
    const feeAmountInKaspa = sompiToKaspa(feeAmountInSompi)

    if (feeAmountInKaspa >= CONVERSION_THRESHOLD) {
      return (
        <CountUp
          preserveValue
          decimals={2}
          end={feeAmountInKaspa}
          suffix={` ${UNIT_STRING}`}
        />
      )
    }

    return (
      <CountUp
        preserveValue
        decimals={0}
        end={feeAmountInSompi}
        suffix={" Sompi"}
      />
    )
  }

  const formatSeconds = (seconds?: number) => {
    if (!seconds) {
      return ""
    }

    if (seconds < 60) {
      return `${roundUp(seconds, 1)}s`
    } else if (seconds < 3600) {
      return `${(seconds / 60).toFixed()}m`
    } else {
      return `${(seconds / 3600).toFixed()}h`
    }
  }

  return (
    <div className={"d-flex align-items-center"}>
      <div
        className={
          "btn btn-active-light-primary btn-custom d-flex align-items-center"
        }
        data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <i className={`bi bi-fuel-pump fs-2 me-1`}></i>
        <span className="fs-7 fw-bold header-tooltip-text">
          {intl.formatMessage(
            { id: "GAS_WIDGET.TITLE" },
            {
              fee: formatGasAmount(estimatedFee?.normalBuckets[0]?.feerate),
            }
          )}
        </span>
      </div>

      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-250px"
        data-kt-menu="true"
      >
        <div className="d-flex flex-column">
          <BucketRow
            title={intl.formatMessage(
              { id: "GAS_WIDGET.FAST" },
              {
                time: formatSeconds(
                  estimatedFee?.priorityBucket?.estimatedSeconds
                ),
              }
            )}
            formattedFee={formatGasAmount(
              estimatedFee?.priorityBucket?.feerate
            )}
          />
          <BucketRow
            title={intl.formatMessage(
              { id: "GAS_WIDGET.NORMAL" },
              {
                time: formatSeconds(
                  estimatedFee?.normalBuckets[0]?.estimatedSeconds
                ),
              }
            )}
            formattedFee={formatGasAmount(
              estimatedFee?.normalBuckets[0]?.feerate
            )}
          />
          <BucketRow
            title={intl.formatMessage(
              { id: "GAS_WIDGET.SLOW" },
              {
                time: formatSeconds(
                  estimatedFee?.lowBuckets[0]?.estimatedSeconds
                ),
              }
            )}
            formattedFee={formatGasAmount(estimatedFee?.lowBuckets[0]?.feerate)}
          />
        </div>
      </div>
    </div>
  )
}

export { GasWidget }
