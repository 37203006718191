/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRequest } from "ahooks"
import React, { useMemo } from "react"
import { useIntl } from "react-intl"
import { AnalyticsService } from "../../../../client"
import { SimpleChart } from "./SimpleChart"

const row = "row g-xl-8 mb-5 mb-xl-0"

const GraphsSection: React.FC = () => {
  const intl = useIntl()
  const { data: activeAddressData, loading: activeAddressLoading } = useRequest(
    () => AnalyticsService.analyticsControllerGetAnalyticsGraph("ActiveAddress")
  )
  const { data: txnCountData, loading: txCountDataLoading } = useRequest(() =>
    AnalyticsService.analyticsControllerGetAnalyticsGraph("TransactionCount")
  )

  const memorizedGraphs = useMemo(() => {
    return (
      <div className={row}>
        <div className={`col-xxl-6 mb-xl-0`}>
          <SimpleChart
            className="card-xl-stretch mb-xl-8"
            loading={activeAddressLoading}
            chartName={intl.formatMessage({
              id: "DASHBOARD.CHART.ADDRESS_COUNT.TITLE",
            })}
            chartDescription={intl.formatMessage({
              id: "DASHBOARD.CHART.ADDRESS_COUNT.DESCRIPTION",
            })}
            chartData={{
              data: activeAddressData?.data,
              seriesName: intl.formatMessage({
                id: "DASHBOARD.CHART.ADDRESS_COUNT.TITLE",
              }),
            }}
          />
        </div>
        <div className="col-xxl-6">
          <SimpleChart
            className="card-xl-stretch mb-xl-8"
            loading={txCountDataLoading}
            chartName={intl.formatMessage({
              id: "DASHBOARD.CHART.TXN_COUNT.TITLE",
            })}
            chartData={{
              data: txnCountData?.data,
              seriesName: intl.formatMessage({
                id: "DASHBOARD.CHART.TXN_COUNT.TITLE",
              }),
            }}
          />
        </div>
      </div>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeAddressData,
    activeAddressLoading,
    txnCountData,
    txCountDataLoading,
  ])

  return memorizedGraphs
}

export { GraphsSection }
