import { useIntl } from "react-intl"
import { GetTokenSummaryResponse } from "../../../../client"
import { Line, LineConfig } from "@ant-design/plots"
import { Card } from "../../../components/Card"
import { formatUnixTime } from "../../../utils/utils"
import { useAntVTheme } from "../../../hooks/useAntVTheme"
import { ChartIntervalSegment } from "./ChartIntervalSegment"
import { ChartLoading } from "../../../components/ChartLoading"

const TokenStatsChart = ({
  tokenData,
  chartInterval,
  onIntervalChange,
  loading,
}: {
  tokenData?: GetTokenSummaryResponse
  chartInterval: string
  onIntervalChange: (interval: string) => void
  loading?: boolean
}) => {
  const intl = useIntl()
  const statsHistory = tokenData?.statsHistory || []
  const theme = useAntVTheme()

  const chartData = statsHistory
    .map((item) => [
      {
        type: intl.formatMessage({
          id: "TOKEN.HEADER.HOLDER_COUNT",
        }),
        value: item.h,
        timestamp: item.t,
      },
      {
        type: intl.formatMessage({
          id: "TOKEN.HEADER.TRANSFER_COUNT",
        }),
        value: item.tf,
        timestamp: item.t,
      },
      ...(tokenData?.totalMinted === tokenData?.maxSupply
        ? []
        : [
            {
              type: intl.formatMessage({
                id: "TOKEN.HEADER.MINT_COUNT",
              }),
              value: item.m,
              timestamp: item.t,
            },
          ]),
    ])
    .flat()

  const config: LineConfig = {
    data: chartData,
    seriesField: "type",
    theme,
    xField: "timestamp",
    yField: "value",
    smooth: true,
    animation: false,
    connectNulls: true,
    legend: {
      flipPage: false,
    },
    xAxis: {
      label: {
        formatter: (value) => {
          return formatUnixTime(parseInt(value) / 1000, false, "MM/DD HH:mm")
        },
      },
    },
    yAxis: {
      label: {
        formatter: (value) => {
          return value.toLocaleString()
        },
      },
    },
    tooltip: {
      formatter: (value: any) => {
        return {
          title: formatUnixTime(
            parseInt(value.timestamp) / 1000,
            false,
            "MM/DD HH:mm"
          ),
          name: value.type,
          value: value.value.toLocaleString(),
        }
      },
    },
  }
  return (
    <div className="col-xl-12">
      <Card
        className="pb-8"
        title={intl.formatMessage({
          id: "TOKEN.HEADER.STATS_CHART",
        })}
        toolbar={
          <ChartIntervalSegment
            chartInterval={chartInterval}
            onIntervalChange={onIntervalChange}
          />
        }
        body={
          <Line
            {...config}
            loading={loading}
            loadingTemplate={<ChartLoading />}
          />
        }
      />
    </div>
  )
}

export { TokenStatsChart }
