/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react"
import clsx from "clsx"
import { ThemeModeSwitcher } from "../../../partials"
import { LocaleSwitcher } from "../../../../app/components/partials/LocaleSwitcher"
import { GasWidget } from "../../../../app/components/partials/GasWidget"
import { EstimatedFeeResponse } from "../../../../client"

const toolbarButtonMarginClass = "ms-1 ms-lg-3"
const toolbarButtonHeightClass =
  "btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40p"

const Topbar: FC<{ estimatedFee?: EstimatedFeeResponse }> = ({
  estimatedFee,
}) => {
  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      <div className="topbar d-flex align-items-stretch flex-shrink-0">
        {/* begin::Theme mode */}
        <div
          className={clsx(
            "d-flex align-items-center",
            toolbarButtonMarginClass
          )}
        >
          <GasWidget estimatedFee={estimatedFee} />
          <LocaleSwitcher />
          <ThemeModeSwitcher toggleBtnClass={toolbarButtonHeightClass} />
        </div>
        {/* end::Theme mode */}
      </div>
    </div>
  )
}

export { Topbar }
