/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { TokenIcon } from "../../../components/partials/TokenIcon"
import {
  KSPR_AFFILIATE_URL,
  TOKEN_ASSET_HOST,
} from "../../../constants/constants"
import { trackTokenAdsClickedEvent } from "../../../utils/tokenUtils"
import { Link } from "react-router-dom"
import { getTokenRoute } from "../../../hooks/navigator"
import { useIntl } from "react-intl"
import { Skeleton, Tooltip } from "antd"

type Props = {
  activityType: "mint" | "transfer"
  className: string
  overview?: {
    ticker: string
    count: number
  }[]
  loading: boolean
}

const TopActivityCard: React.FC<Props> = ({
  className,
  activityType,
  overview,
  loading,
}) => {
  const intl = useIntl()
  const titleMessageId =
    activityType === "mint" ? "TOP_MINTED_TOKENS" : "TOP_TRANSFERRED_TOKENS"

  const actionNameMessageId = activityType === "mint" ? "MINTS" : "TRANSFERS"

  const actionIconTooltipId =
    activityType === "mint" ? "MINT_TICKER" : "TRADE_TICKER"

  const Row = React.useMemo(() => {
    return ({ ticker, count }: { ticker: string; count: number }) => {
      return (
        <div className="d-flex align-items-center mb-8">
          <div className="form-check form-check-custom form-check-solid me-5">
            <TokenIcon
              url={`${TOKEN_ASSET_HOST}/icons/${ticker.toUpperCase()}.jpg`}
              size="40px"
            />
          </div>

          <div className="flex-grow-1">
            <Link
              to={getTokenRoute(ticker)}
              className="text-gray-800 text-hover-primary fw-bold fs-6"
            >
              {ticker}
            </Link>
            <span className="text-muted fw-semibold d-block">
              {count.toLocaleString()}{" "}
              {intl.formatMessage({ id: actionNameMessageId })}
            </span>
          </div>

          <a
            href={KSPR_AFFILIATE_URL}
            className="btn btn-light-success btn-sm fw-bold"
            target="_blank"
            onClick={() =>
              trackTokenAdsClickedEvent("kspr_token_trending_action")
            }
          >
            {intl.formatMessage({ id: actionIconTooltipId }, { ticker: "" })}
          </a>
        </div>
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`card ${className}`}>
      <div className="card-header border-0">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-dark">
            {intl.formatMessage({ id: titleMessageId })}
          </span>
          <span className="text-muted mt-1 fw-semibold fs-7">
            {intl.formatMessage({ id: "LAST_X_MINUTES" }, { x: 15 })}
          </span>
        </h3>
      </div>

      <div className="card-body pt-2">
        {loading ? (
          <Skeleton active />
        ) : (
          overview?.map((item) => <Row key={item.ticker} {...item} />)
        )}
      </div>
    </div>
  )
}

export { TopActivityCard }
