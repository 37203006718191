import { TinyLine, TinyLineConfig } from "@ant-design/charts"

export const MiniPriceChart = ({
  data,
  color,
}: {
  data: number[]
  color: string
}) => {
  const config: TinyLineConfig = {
    data,
    padding: undefined,
    height: 32,
    tooltip: false,
    smooth: true,
    animation: false,
    color,
    yAxis: {
      max: Math.max(...data) * 1.05,
      min: Math.min(...data) * 0.95,
    },
  }
  return <TinyLine {...config} />
}
