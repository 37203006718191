/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { useIntl } from "react-intl"
import {
  formatKaspaAmount,
  formatSupplyNumber,
  kaspaAmountToInt,
  convertUtcToLocal,
} from "../../../utils/utils"
import { LoadingOverlay } from "../../dashboard/components/LoadingOverlay"
import { Line, LineConfig } from "@ant-design/charts"
import { useAntVTheme } from "../../../hooks/useAntVTheme"
import { AddressBalanceRecord } from "../../../../client"

interface AddressBalanceHistoryProps {
  loading?: boolean
  balanceRecords: AddressBalanceRecord[]
}

const AddressBalanceHistory: React.FC<AddressBalanceHistoryProps> = ({
  loading,
  balanceRecords,
}) => {
  const intl = useIntl()
  const theme = useAntVTheme()
  const [chartConfig, setChartConfig] = React.useState<LineConfig>()

  React.useEffect(() => {
    setChartConfig({
      data: balanceRecords.map((i) => {
        return {
          ...i,
          balance: Number(i.balance),
        }
      }),
      theme,
      height: 200,
      xField: "createdAt",
      yField: "balance",
      smooth: true,
      xAxis: {
        label: {
          formatter: (value) => {
            return convertUtcToLocal(value).format("MM/DD HH:mm")
          },
        },
        tickCount: 4,
      },
      yAxis: {
        label: {
          formatter: (value) => {
            const shortValue = formatSupplyNumber(
              kaspaAmountToInt(parseInt(value))
            )
            return `${shortValue?.value} ${shortValue?.unit}`
          },
        },
        min:
          Math.min(
            ...balanceRecords.map((r) => r.balance)
          ) * 0.9995,
      },
      tooltip: {
        formatter: (datum) => {
          return {
            name: intl.formatMessage({ id: "BALANCE" }),
            value: formatKaspaAmount(datum.balance, 0),
          }
        },
        title: (value) => {
          return convertUtcToLocal(value).format("MM/DD HH:mm")
        },
      },
    })
  }, [])

  return (
    <div className={`card mb-5 ${loading && "overlay overlay-block"}`}>
      <div className="card-header border-0">
        <div className="card-title m-0">
          <h3 className="fw-bold m-0 d-flex justify-content-center">
            {intl.formatMessage({ id: "ADDRESS.BALANCE_HISTORY" })}
            <span className="badge badge-danger ms-3">New</span>
          </h3>
        </div>
      </div>

      <div className="card-body border-top p-9">
        {chartConfig && <Line {...chartConfig} />}
      </div>

      <div></div>

      {loading && <LoadingOverlay />}
    </div>
  )
}

export { AddressBalanceHistory }
