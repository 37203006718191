import clsx from "clsx"
import { toAbsoluteUrl } from "../../../_metronic/helpers"
import { setLanguage, useLang } from "../../../_metronic/i18n/Metronici18n"

/* eslint-disable jsx-a11y/anchor-is-valid */

const languages = [
  {
    lang: "en",
    name: "English",
    flag: toAbsoluteUrl("/media/flags/united-states.svg"),
  },
  {
    lang: "zh",
    name: "中文",
    flag: toAbsoluteUrl("/media/flags/china.svg"),
  },
]

const LocaleSwitcher = () => {
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)

  const toolbarButtonMarginClass = "ms-1 ms-lg-3"
  const toolbarButtonHeightClass =
    "btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40p"
  const toolbarButtonIconSizeClass = "svg-icon-1"

  return (
    <div
      className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
    >
      <div
        className={clsx(
          "btn btn-icon btn-active-light-primary btn-custom",
          toolbarButtonHeightClass
        )}
        data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <i className={`bi bi-translate fs-2 ${toolbarButtonIconSizeClass}`}></i>
      </div>
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px"
        data-kt-menu="true"
      >
        {languages.map((l) => (
          <div
            className="menu-item px-3 my-0"
            key={l.lang}
            onClick={() => {
              setLanguage(l.lang)
            }}
          >
            <a
              href="#"
              className={clsx("menu-link px-3 py-2", {
                active: l.lang === currentLanguage?.lang,
              })}
            >
              <span className="symbol symbol-20px me-4">
                <img className="rounded-1" src={l.flag} alt="metronic" />
              </span>
              <span className="menu-title">{l.name}</span>
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export { LocaleSwitcher }
