/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AddressTag = {
    address: string;
    name: string;
    link: string;
    type: AddressTag.type;
};

export namespace AddressTag {

    export enum type {
        REGULAR = 'regular',
        RANK = 'rank',
        HEIST = 'heist',
    }


}

