import { Table, Tooltip } from "antd"
import Search from "antd/es/input/Search"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import {
  AddressTag,
  GetTokenSummaryResponse,
  GetTokenSummaryTokenHolderResponse,
} from "../../../../client"
import { Card } from "../../../components/Card"
import { PasteIcon } from "../../../components/PasteIcon"
import { getAddressRoute } from "../../../hooks/navigator"
import { shortenKaspaAddressWithPrefix } from "../../../utils/utils"
import { ColumnsType } from "antd/es/table"
import { formatTokenAmount } from "../../../utils/tokenUtils"
import { FiatAmountBadge } from "../../../components/FiatAmountBadge"
import { Link } from "react-router-dom"
import { AddressTag as AddressTagComponent } from "../../../components/partials/AddressTag"

interface TableDataType {
  rank: number
  key: string
  holder: GetTokenSummaryTokenHolderResponse
  address: string
  balance: number
  tags: AddressTag[]
}

const holderModelToTableData = (
  holders?: GetTokenSummaryTokenHolderResponse[]
): TableDataType[] => {
  return holders
    ? holders.map((holder, index) => {
        return {
          rank: index + 1,
          key: holder.address,
          holder: holder,
          address: holder.address,
          balance: holder.amount,
          tags: holder.tags,
        }
      })
    : []
}

const TokenHoldersList = ({
  tokenHolders,
  tokenData,
  loading,
}: {
  tokenHolders?: GetTokenSummaryTokenHolderResponse[]
  tokenData?: GetTokenSummaryResponse
  loading?: boolean
}) => {
  const intl = useIntl()

  const [tableData, setTableData] = useState<TableDataType[]>()

  const columns: ColumnsType<TableDataType> = [
    {
      title: intl.formatMessage({ id: "RANK" }),
      dataIndex: "rank",
      align: "center",
      render: (index: number) => {
        return <div className="fw-bold text-gray-800">{index}</div>
      },
    },
    {
      title: intl.formatMessage({ id: "ADDRESS" }),
      dataIndex: "holder",
      render: (holder: GetTokenSummaryTokenHolderResponse) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <Tooltip title={holder.address}>
                <Link
                  role="button"
                  className="fw-bold text-gray-800 text-hover-primary fs-6 text-break"
                  to={getAddressRoute(holder.address)}
                >
                  {shortenKaspaAddressWithPrefix(holder.address, 6, 6)}
                </Link>
              </Tooltip>

              {holder.tags?.map((tag) => {
                return <AddressTagComponent className="mx-2" tag={tag} />
              })}

              <PasteIcon value={holder.address} />
            </div>
          </>
        )
      },
    },

    {
      title: intl.formatMessage({ id: "BALANCE" }),
      render: (_, record: TableDataType) => {
        const ticker = tokenData?.ticker || ""
        const decimal = tokenData?.decimal || 0
        return (
          <div className="d-flex align-items-center">
            {formatTokenAmount(ticker, record.balance, decimal, 0)}
            {tokenData?.price?.floorPrice ? (
              <FiatAmountBadge
                kaspaAmount={
                  (record.balance / Math.pow(10, decimal)) *
                  tokenData?.price?.floorPrice
                }
                precision={0}
              />
            ) : null}
          </div>
        )
      },
      sorter: (a: any, b: any) => a.balance - b.balance,
    },

    {
      title: intl.formatMessage({ id: "PERCENTAGE" }),
      render: (_, record: TableDataType) => {
        const amount = record.balance || 0
        const totalSupply = tokenData?.maxSupply || 1
        const percentage = (amount / totalSupply) * 100
        return (
          <div className="d-flex align-items-center">
            {percentage.toFixed(2)}%
          </div>
        )
      },
      sorter: (a: any, b: any) => a.balance - b.balance,
    },
  ]

  const onTableSearch = (val: string) => {
    const dataData = holderModelToTableData(tokenHolders)
    if (val) {
      setTableData(
        dataData.filter((row) =>
          row.address.toLowerCase().includes(val.toLowerCase())
        )
      )
    } else {
      setTableData(dataData)
    }
  }

  useEffect(() => {
    setTableData(holderModelToTableData(tokenHolders))
  }, [tokenHolders])

  return (
    <div className="col-xl-12" id="top-addresses">
      <Card
        title={intl.formatMessage(
          {
            id: `TOKEN.HEADER.HOLDERS_LIST.TITLE`,
          },
          { ticker: tokenData?.ticker }
        )}
        toolbar={
          <Search
            placeholder={intl.formatMessage({ id: "SEARCH_FOR_ADDRESS" })}
            allowClear
            onChange={(e) => onTableSearch(e.target.value)}
          />
        }
        body={
          <Table
            size="middle"
            columns={columns}
            dataSource={tableData}
            scroll={{ x: "max-content" }}
            loading={loading}
          />
        }
      />
    </div>
  )
}

export { TokenHoldersList }
