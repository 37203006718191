import { useIntl } from "react-intl"
import { useGlobalData } from "../../utils/GlobalDataProvider"
import { Tooltip } from "antd"

export const Krc20PriceUnitToggleButton = () => {
  const { krc20PriceUnit, toggleKrc20PriceUnit } = useGlobalData()
  const intl = useIntl()

  return (
    <Tooltip
      title={intl.formatMessage(
        { id: "SHOWING_PRICE_IN" },
        { unit: krc20PriceUnit }
      )}
    >
      <i
        role="button"
        className="bi bi-currency-exchange fs-4 text-hover-primary d-flex align-items-center"
        onClick={toggleKrc20PriceUnit}
      ></i>
    </Tooltip>
  )
}
