import { LiveActivitiesSection } from "./components/LiveActivitiesSection"
import { TopMetricsSection } from "./components/TopMetricsSection"
import { MarketSection } from "./components/MarketSection"
import { GraphsSection } from "./components/GraphsSection"
import { useTitle } from "ahooks"
import { DEFAULT_TITLE, rowClass } from "../../constants/constants"
import { NodeMapSection } from "./components/NodeMapSection"
import { AdsPlacement } from "../../components/Ads/AdsPlacement"
import { getRandomAdsPlatfrom } from "../../utils/utils"
import { useState } from "react"

const DashboardWrapper = () => {
  useTitle(DEFAULT_TITLE, { restoreOnUnmount: true })

  const [adsPlatform] = useState(() => getRandomAdsPlatfrom())

  return (
    <>
      <div className={rowClass}>
        <TopMetricsSection />
        <MarketSection />
      </div>

      <div className={rowClass}>
        <div className="col-xl-8">
          <LiveActivitiesSection
            className="mb-5 mb-xl-8"
            activityType={"transactions"}
          />
        </div>
        <div className="col-xl-4">
          <LiveActivitiesSection className="mb-xl-8" activityType={"blocks"} />
        </div>
      </div>

      <div className="mb-3 mt-xl-n3">
        <AdsPlacement placementType="banner" platform="ads-server" />
      </div>

      <GraphsSection />
      {process.env.REACT_APP_ENABLE_NODE_MAP === "true" && <NodeMapSection />}

      <div className="mt-5">
        <AdsPlacement placementType="banner" platform={adsPlatform} />
      </div>
    </>
  )
}

export { DashboardWrapper }
