import { Tooltip } from "antd"

export const DiffValueBadge = ({
  diff = 0,
  unit = "",
  tooltip,
}: {
  diff: number
  unit?: string
  tooltip?: string
}) => {
  let badgeColor, sign
  if (diff > 0) {
    badgeColor = "badge-light-success"
    sign = "+"
  } else if (diff < 0) {
    badgeColor = "badge-light-danger"
    sign = ""
  } else {
    badgeColor = "badge-light"
    sign = ""
  }

  const localeStringOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }
  const text = `${sign}${diff.toLocaleString(
    undefined,
    localeStringOptions
  )} ${unit}`
  return (
    <Tooltip title={tooltip}>
      <span className={`badge ${badgeColor} fs-base`}>
        {diff === 0 ? "-" : text}
      </span>
    </Tooltip>
  )
}
