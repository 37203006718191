import { useThemeMode } from "../../_metronic/partials/layout/theme-mode/ThemeModeProvider"
import { deepMix } from "@antv/util"
import { PRIMARY_COLOR } from "../constants/constants"
import { G2 } from "@ant-design/plots"

export const useAntVTheme = () => {
  const { mode } = useThemeMode()
  const theme = mode === "dark" ? G2.getTheme("dark") : G2.getTheme("default")
  return deepMix({}, theme, {
    defaultColor: PRIMARY_COLOR,
    background: "transparent",
  })
}
