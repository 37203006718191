import { useIntl } from "react-intl"
import {
  DistributionTrendResponse,
  DistributionTrendCategory,
} from "../../../client"
import { convertUtcToLocal, formatCash } from "../../utils/utils"
import { AreaConfig } from "@ant-design/charts"
import { Area } from "@ant-design/plots"
import moment from "moment"
import { useAntVTheme } from "../../hooks/useAntVTheme"
import { DatePicker } from "antd"
import dayjs from "dayjs"
import type { Dayjs } from "dayjs"
import { useState } from "react"
import { RangePickerProps } from "antd/es/date-picker"
import { Card } from "../../components/Card"
import { PRIMARY_COLOR } from "../../constants/constants"
import { ChartLoading } from "../../components/ChartLoading"
const { RangePicker } = DatePicker

interface ChartDataType {
  time: string
  count: number
  range: string
}

const formatTime = (isoString: string) => {
  return convertUtcToLocal(isoString).format("YYYY-MM-DD HH:mm")
}

const DistributionTrendSection = ({
  data,
  loading,
}: {
  data?: DistributionTrendResponse[]
  loading?: boolean
}) => {
  const intl = useIntl()
  const theme = useAntVTheme()

  const [startRage, setStartRange] = useState(0.2)
  const [endRage, setEndRange] = useState(1)

  const formatChartData = (data || []).reduce((acc, item) => {
    for (let i: number = 3; i < 11; i++) {
      const key = `addressesIn1e${i}`
      const upperBound = formatCash(1 * 10 ** i)
      const lowerBound = i > 2 ? formatCash(1 * 10 ** (i - 1)) : 0
      const categoryData = item[
        key as keyof DistributionTrendResponse
      ] as DistributionTrendCategory
      const category = intl.formatMessage({
        id: `HOLDERS.ADDRESSES_DISTRIBUTION.CATEGORY.${i}`,
      })
      acc.push({
        time: formatTime(item?.createdAt),
        count: categoryData?.count,
        range: `${category} (${lowerBound} - ${upperBound})`,
      })
    }
    return acc
  }, [] as ChartDataType[])

  const config: AreaConfig = {
    data: formatChartData,
    xField: "time",
    yField: "count",
    seriesField: "range",
    theme,
    slider: {
      start: startRage,
      end: endRage,
    },
    height: 500,
    yAxis: {
      label: {
        formatter: (value) => parseInt(value).toLocaleString(),
      },
    },
    tooltip: {
      customItems: (items) => {
        return [
          ...items,
          {
            name: intl.formatMessage({
              id: "TOTAL",
            }),
            value: items
              .reduce((acc, item) => {
                return acc + parseInt(item.data.count)
              }, 0)
              .toLocaleString(),
            data: { "": "" },
            mappingData: { "": "" },
            color: PRIMARY_COLOR,
            marker: "",
          },
        ]
      },
    },
  }

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      const [startDate, endDate] = dateStrings
      let start, end
      const length = formatChartData.length
      // Find the start time
      for (let i: number = 0; i < length; i++) {
        const { time } = formatChartData[i]
        if (moment(time).isSameOrAfter(moment(startDate), "day")) {
          start = i / length
          break
        }
      }
      // Find the end time
      for (let i: number = length - 1; i >= 0; i--) {
        const { time } = formatChartData[i]
        if (moment(time).isSameOrBefore(moment(endDate), "day")) {
          end = i / length
          break
        }
      }

      setStartRange(start || 0)
      setEndRange(end || 1)
    } else {
      setStartRange(0)
      setEndRange(1)
    }
  }

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > dayjs().endOf("day")
  }

  const rangePresets: {
    label: string
    value: [Dayjs, Dayjs]
  }[] = [
    { label: "Last 24 hours", value: [dayjs().add(-24, "h"), dayjs()] },
    { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
  ]

  return (
    <Card
      title={intl.formatMessage({
        id: "HOLDERS.ADDRESSES_DISTRIBUTION_TREND.TITLE",
      })}
      description={intl.formatMessage(
        {
          id: "HOLDERS.ADDRESSES_DISTRIBUTION_TREND.DESCRIPTION",
        },
        {
          time: data && formatTime(data[data.length - 1]?.createdAt),
        }
      )}
      toolbar={
        <RangePicker
          presets={rangePresets}
          onChange={onRangeChange}
          disabledDate={disabledDate}
        />
      }
      body={
        <Area
          {...config}
          loading={loading}
          loadingTemplate={<ChartLoading />}
        />
      }
    />
  )
}

export { DistributionTrendSection }
