import { useGlobalData } from "../utils/GlobalDataProvider"
import { formatCash } from "../utils/utils"

export const FiatAmountBadge = ({
  kaspaAmount,
  className = "ms-2",
  precision = 2,
  showLessThan = false,
}: {
  kaspaAmount: number
  className?: string
  precision?: number
  showLessThan?: boolean
}) => {
  const { marketData } = useGlobalData()
  if (!marketData?.price) return null

  const fiatAmount = marketData?.price
    ? `$${(marketData.price * kaspaAmount).toLocaleString(undefined, {
        maximumFractionDigits: precision,
      })}`
    : "$"

  if (showLessThan && marketData.price * kaspaAmount < 0.01) {
    // Show < 0.01$
    return <span className={`badge badge-light ${className}`}>{"< $0.01"}</span>
  }

  return <span className={`badge badge-light ${className}`}>{fiatAmount}</span>
}

export const FiatAmount = ({
  kaspaAmount,
  precision = 2,
  short = false,
}: {
  kaspaAmount?: number
  precision?: number
  short?: boolean
}) => {
  const { marketData } = useGlobalData()
  if (!marketData?.price || !kaspaAmount) return null

  const fiatAmount = marketData.price * kaspaAmount
  if (short) {
    return `$${formatCash(fiatAmount, precision)}`
  }

  return `$${fiatAmount.toLocaleString(undefined, {
    maximumFractionDigits: precision,
  })}`
}
