import { Segmented } from "antd"

export const ChartIntervalSegment = ({
  chartInterval,
  onIntervalChange,
}: {
  chartInterval: string
  onIntervalChange: (interval: string) => void
}) => {
  return (
    <Segmented
      size="middle"
      options={["1d", "7d", "1m", "1y"]}
      value={chartInterval}
      onChange={(value) => {
        onIntervalChange(value as string)
      }}
    />
  )
}
