/* eslint-disable jsx-a11y/anchor-is-valid */
import { Skeleton } from "antd"
import React from "react"
import { useIntl } from "react-intl"
import { TokenTransactionResponse } from "../../../../client"
import { List } from "antd"
import { TokenTransactionRow } from "./TokenTransactionRow"
import { EmptyState } from "../../../components/TableEmptyState"

interface TokenTransactionsProps {
  transactions?: TokenTransactionResponse[]
  address?: string
  loading?: boolean
  hideHeader?: boolean
}

const TokenTransactions: React.FC<TokenTransactionsProps> = ({
  transactions = [],
  address,
  loading,
  hideHeader = false,
}) => {
  const intl = useIntl()

  const isEmpty = !loading && transactions?.length === 0
  const isInitalLoading = loading && transactions?.length === 0

  const Header = () => {
    return (
      <div className="card-header border-bottom">
        <div className="card-title m-0 d-flex align-items-center">
          <h3 className="fw-bold m-0">
            {intl.formatMessage({
              id: "ADDRESS.TRANSACTION.TRANSACTION_HISTORY",
            })}
          </h3>
        </div>
      </div>
    )
  }

  return (
    <div className={"card"} style={{ minHeight: "10vh" }}>
      {!hideHeader && <Header />}
      {isInitalLoading && (
        <div className="card-body p-10 d-flex justify-content-between align-items-start">
          <Skeleton active />
        </div>
      )}

      {isEmpty ? (
        <EmptyState textId="TRANSACTION.LIST.NOT_FOUND" />
      ) : (
        <List loading={loading} dataSource={transactions}>
          {transactions.map((tx, index) => (
            <TokenTransactionRow
              key={`tx-row-${index}`}
              decimal={tx.tokenInfo?.decimal || 8}
              tx={tx}
              address={address || ""}
            />
          ))}
        </List>
      )}
    </div>
  )
}

export { TokenTransactions }
