import { useState } from "react"
import ConfettiExplosion, { ConfettiProps } from "react-confetti-explosion"

const EXPLOSION_DURATION = 3000
const explosionProps: ConfettiProps = {
  force: 0.8,
  duration: EXPLOSION_DURATION,
  particleCount: 400,
  width: 1600,
  colors: ["#041E43", "#1471BF", "#5BB4DC", "#FC027B", "#66D805"],
  zIndex: 999999999,
}

export const PasteIcon = ({
  value,
  showExplosion,
}: {
  value?: string
  showExplosion?: boolean
}) => {
  const [copied, setCopied] = useState(false)
  const [isExploding, setIsExploding] = useState(false)

  const onClick = (value: string) => {
    if (showExplosion && !isExploding) {
      setIsExploding(true)
      setTimeout(() => {
        setIsExploding(false)
      }, EXPLOSION_DURATION)
    }

    navigator.clipboard.writeText(value)
    setCopied(true)
    setTimeout(
      () => {
        setCopied(false)
      },
      showExplosion ? EXPLOSION_DURATION : 1000
    )
  }

  const Icon = () =>
    copied ? (
      <i className="las la-check fs-1x mx-2 text-primary">
        {isExploding && <ConfettiExplosion {...explosionProps} />}
      </i>
    ) : (
      <i
        role="button"
        onClick={() => value && onClick(value)}
        className="fa-regular text-hover-primary fa-copy fs-1x mx-2"
      >
        {isExploding && <ConfettiExplosion {...explosionProps} />}
      </i>
    )

  return <Icon />
}
