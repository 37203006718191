import { useGlobalData } from "../../../utils/GlobalDataProvider"
import { MarketDataCard } from "../components/MarketDataCard"

export const MarketSection = () => {
  const { marketData } = useGlobalData()

  return (
    <div className="col-xl-4">
      <MarketDataCard
        className="card-xl-stretch"
        chartColor="primary"
        chartHeight="90px"
        marketData={marketData}
      />
    </div>
  )
}
