/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"
import { Block } from "../../../client"
import { getBlockRoute } from "../../hooks/navigator"
import {
  convertUtcToLocal,
  formatIsoTime,
  getRandomAdsPlatfrom,
} from "../../utils/utils"
import { PasteIcon } from "../../components/PasteIcon"
import { BlockHashBullet } from "../../components/BlockHashBullet"
import { useIntl } from "react-intl"
import { AdsPlacement } from "../../components/Ads/AdsPlacement"
import { Skeleton, Tooltip } from "antd"
import { ALTERNATIVE_EXPLORER_URL_BLOCK } from "../../constants/constants"
import { Link } from "react-router-dom"

const Space = () => <div className="my-5"></div>

const labelClass = "fw-semibold text-muted text-break mb-1 fs-6"
const valueClass = "fw-semibold text-dark text-break fs-6"

const DashSeparator = () => (
  <div className="separator separator-dashed my-6"></div>
)

const renderCell = (label: string, value: React.ReactNode) => {
  return (
    <div className="d-flex flex-wrap align-items-center">
      <div>
        <div className={`${labelClass}`}>{label}</div>
        {value}
      </div>
    </div>
  )
}

interface BlockHeaderProps {
  block?: Block
  loading?: boolean
}

const BlockHeader: React.FC<BlockHeaderProps> = ({ block, loading }) => {
  const intl = useIntl()

  const getParents = (block?: Block) => {
    let parents = new Set<string>()

    block?.parents?.forEach((hash) => {
      parents.add(hash)
    })

    return Array.from(parents)
  }

  return (
    <div className={"card mb-5"}>
      <div className="card-header border-0">
        <div className="card-title m-0">
          <h3 className="fw-bold m-0">
            {intl.formatMessage({ id: "BLOCK.BLOCK_DETAILS" })}
            <Tooltip title="To explorer.kaspa.org">
              <a
                role="button"
                className="fa-solid fa-arrow-up-right-from-square text-hover-primary fs-7 text-dark ms-3 mt-1"
                href={`${ALTERNATIVE_EXPLORER_URL_BLOCK}${block?.hash}`}
                target="_blank"
                rel="noreferrer"
              ></a>
            </Tooltip>
          </h3>
        </div>
      </div>

      <div className="card-body border-top p-9">
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            {renderCell(
              intl.formatMessage({ id: "HASH" }),
              <div className={`${valueClass}`}>
                {block?.hash}
                <PasteIcon value={block?.hash} />
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "BLUE_SCORE" }),
              <>
                <div className={`${valueClass}`}>{block?.blueScore}</div>
              </>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "DAA_SCORE" }),
              <>
                <div className={`${valueClass}`}>{block?.daaScore}</div>
              </>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TIME" }),
              <>
                {block?.timestamp && (
                  <div className={`${valueClass} d-flex align-items-center`}>
                    {formatIsoTime(block?.timestamp)}
                    <span className="badge badge-light ms-2">
                      {convertUtcToLocal(block?.timestamp).fromNow()}
                    </span>
                  </div>
                )}
              </>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "VERSION" }),
              <>
                {block?.version && (
                  <div className={`${valueClass}`}>
                    <span className="badge badge-success">
                      {`${intl.formatMessage({ id: "VERSION" })} ${
                        block?.version
                      }`}
                    </span>
                  </div>
                )}
              </>
            )}

            <DashSeparator />

            {renderCell(
              intl.formatMessage({ id: "SPONSORED" }),
              <div className="mt-3">
                <AdsPlacement placementType="box" platform={"ads-server"} />
              </div>
            )}

            <DashSeparator />
            {renderCell(
              intl.formatMessage({ id: "PARENTS" }),
              <>
                {getParents(block).map((hash) => {
                  return <BlockHashBullet className={valueClass} hash={hash} />
                })}
              </>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "CHILDREN" }),
              <>
                {block?.children?.map((hash) => {
                  return <BlockHashBullet className={valueClass} hash={hash} />
                })}
              </>
            )}

            <Space />

            {renderCell(
              intl.formatMessage({ id: "BLUE_WORK" }),
              <>
                <div className={`${valueClass}`}>{block?.blueWork}</div>
              </>
            )}

            <Space />

            {renderCell(
              intl.formatMessage({ id: "PRUNING_POINT" }),
              <>
                <Link
                  role="button"
                  className={`${valueClass} text-hover-primary text-break`}
                  to={
                    block?.pruningPoint
                      ? getBlockRoute(block?.pruningPoint)
                      : "#"
                  }
                >
                  {block?.pruningPoint}
                </Link>
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export { BlockHeader }
