export const generateHash = (input: string) => {
  return crypto.subtle
    .digest("SHA-256", new TextEncoder().encode(input))
    .then((buffer) => {
      return Array.from(new Uint8Array(buffer))
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("")
    })
}

export const generateNonce = async () => {
  const salt = process.env.REACT_APP_NONCE_SALT || ""
  const timestamp = Date.now()
  const hashInput = `${timestamp}:${salt}`
  return await generateHash(hashInput).then((hash) => `${timestamp}:${hash}`)
}
