export const PriceDiffBadge = ({
  diff,
  precision = 2,
}: {
  diff: number
  precision?: number
}) => {
  return (
    <span
      className={`badge badge-${diff > 0 ? "light-success" : "light-danger"}`}
    >
      {diff > 0 ? "+" : ""}
      {diff.toFixed(precision)}%
    </span>
  )
}
