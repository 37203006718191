import { useRequest } from "ahooks"
import { Checkbox, Table, Tooltip } from "antd"
import Search from "antd/es/input/Search"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { AnalyticsService, AddressBriefResponse } from "../../../client"
import { AddressTag } from "../../components/partials/AddressTag"
import { Card } from "../../components/Card"
import { FiatAmountBadge } from "../../components/FiatAmountBadge"
import { PasteIcon } from "../../components/PasteIcon"
import { getAddressRoute } from "../../hooks/navigator"
import {
  formatKaspaAmount,
  kaspaAmountToInt,
  shortenKaspaAddressWithPrefix,
} from "../../utils/utils"
import { ColumnsType } from "antd/es/table"
import { DiffValueBadge } from "../../components/DiffValueBadge"
import { UNIT_STRING } from "../../constants/constants"
import { Link } from "react-router-dom"

interface TableDataType {
  rank: number
  key: string
  holder: AddressBriefResponse
  address: string
  balance: number
  balanceDiff24h: number
  percentage: number
}

const holderModelToTableData = (
  holders?: AddressBriefResponse[],
  balanceChangedOnly?: boolean
): TableDataType[] => {
  return holders
    ? holders
        .map((holder, index) => {
          return {
            rank: index + 1,
            key: holder.address,
            holder: holder,
            address: holder.address,
            balance: holder.balance,
            balanceDiff24h: holder.balanceDiff24h,
            percentage: holder.percent,
          }
        })
        .filter((i) =>
          balanceChangedOnly
            ? i.balanceDiff24h !== 0 && i.balanceDiff24h !== undefined
            : true
        )
    : []
}

const HoldersListSection = () => {
  const intl = useIntl()

  const { data, loading } = useRequest(
    AnalyticsService.analyticsControllerGetRichList
  )
  const [tableData, setTableData] = useState<TableDataType[]>()
  const [showBalanceChangedOnly, setShowBalanceChangedOnly] = useState(false)

  const columns: ColumnsType<TableDataType> = [
    {
      title: intl.formatMessage({ id: "RANK" }),
      dataIndex: "rank",
      align: "center",
      render: (_: number, record: TableDataType) => {
        return <div className="fw-bold text-gray-800">{record.rank}</div>
      },
    },
    {
      title: intl.formatMessage({ id: "ADDRESS" }),
      dataIndex: "holder",
      render: (holder: AddressBriefResponse) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <Tooltip title={holder.address}>
                <Link
                  role="button"
                  className="fw-bold text-gray-800 text-hover-primary fs-6 text-break"
                  to={getAddressRoute(holder.address)}
                >
                  {shortenKaspaAddressWithPrefix(holder.address, 6, 6)}
                </Link>
              </Tooltip>

              <PasteIcon value={holder.address} />
            </div>
          </>
        )
      },
    },
    {
      title: intl.formatMessage({ id: "TAGS" }),
      dataIndex: "holder",
      render: (holder: AddressBriefResponse) => {
        return (
          <>
            {holder.tags?.map((tag) => {
              return <AddressTag className="mx-0 me-2" tag={tag} />
            })}
          </>
        )
      },
    },
    {
      title: intl.formatMessage({ id: "BALANCE" }),
      render: (_, record: TableDataType) => {
        return (
          <div className="d-flex align-items-center">
            {formatKaspaAmount(record.balance, 0)}
            <FiatAmountBadge
              kaspaAmount={kaspaAmountToInt(record.balance)}
              precision={0}
            />
          </div>
        )
      },
      sorter: (a: any, b: any) => a.balance - b.balance,
    },
    {
      title: intl.formatMessage({ id: "24_HOUR_CHANGE" }),
      render: (_, record: TableDataType) => {
        return (
          <div className="d-flex align-items-center">
            <span className="ms-2">
              <DiffValueBadge
                diff={kaspaAmountToInt(record.balanceDiff24h)}
                unit={UNIT_STRING}
                tooltip={intl.formatMessage({
                  id: "HOLDERS.TOP_HOLDERS.BALANCE_DIFF.TOOLTIP",
                })}
              />
              {record.balanceDiff24h ? (
                <FiatAmountBadge
                  kaspaAmount={kaspaAmountToInt(
                    Math.abs(record.balanceDiff24h)
                  )}
                  precision={0}
                />
              ) : null}
            </span>
          </div>
        )
      },
      sorter: (a: any, b: any) => a.balanceDiff24h - b.balanceDiff24h,
    },
    {
      title: intl.formatMessage({ id: "PERCENTAGE" }),
      dataIndex: "percentage",
      render: (percentage: number) => (percentage || 0).toLocaleString() + "%",
      sorter: (a: any, b: any) => a.percentage - b.percentage,
    },
  ]

  const onTableSearch = (val: string) => {
    const dataData = holderModelToTableData(data, showBalanceChangedOnly)
    if (val) {
      setTableData(
        dataData.filter(
          (row) =>
            row.address.toLowerCase().includes(val.toLowerCase()) ||
            row.holder.tags?.some((tag) =>
              tag.name.toLowerCase().includes(val.toLowerCase())
            )
        )
      )
    } else {
      setTableData(dataData)
    }
  }

  useEffect(() => {
    setTableData(holderModelToTableData(data, showBalanceChangedOnly))
  }, [data, showBalanceChangedOnly])

  return (
    <div className="col-xl-12" id="top-addresses">
      <Card
        title={intl.formatMessage({
          id: "HOLDERS.TOP_HOLDERS.TITLE",
        })}
        description={intl.formatMessage({
          id: "HOLDERS.TOP_HOLDERS.DESCRIPTION",
        })}
        toolbar={
          <Search
            placeholder={intl.formatMessage({ id: "SEARCH_FOR_ADDRESS" })}
            allowClear
            onChange={(e) => onTableSearch(e.target.value)}
          />
        }
        body={
          <Table
            size="middle"
            columns={columns}
            dataSource={tableData}
            loading={loading}
            scroll={{ x: "max-content" }}
            footer={() => (
              <div className="ms-4">
                <Checkbox
                  checked={showBalanceChangedOnly}
                  onChange={() =>
                    setShowBalanceChangedOnly(!showBalanceChangedOnly)
                  }
                >
                  {intl.formatMessage({
                    id: "HOLDERS.TOP_HOLDERS.DISPLAY_BALANCE_UPDATES_ONLY.TITLE",
                  })}
                </Checkbox>
              </div>
            )}
          />
        }
      />
    </div>
  )
}

export { HoldersListSection }
