/* eslint-disable jsx-a11y/anchor-is-valid */
import { useToggle } from "ahooks"
import { Skeleton } from "antd"
import moment from "moment"
import { useIntl } from "react-intl"
import { Transaction } from "../../../../client"
import { KTSVG } from "../../../../_metronic/helpers"
import { FiatAmountBadge } from "../../../components/FiatAmountBadge"
import { TransactionIdLabel } from "../../../components/TransactionIdLabel"
import { TransactionStatusTag } from "../../../components/TransactionStatusTag"
import { formatKaspaAmount, kaspaAmountToInt } from "../../../utils/utils"
import { TransactionSide } from "../../transaction/TransactionSide"
import {
  isCompoundTransaction,
  isLegacyTransaction,
} from "../../transaction/utils"

interface TransactionProps {
  tx: Transaction
  address: string
  traceEnabled?: boolean
  loading?: boolean
}

export const TransactionTrace = ({ tx, address }: TransactionProps) => {
  return (
    <div className="row">
      <div className="col-xl-6">
        <TransactionSide
          type="out"
          className="card-xl-stretch"
          highlightAddress={address}
          txs={tx.inputs || []}
        />
      </div>
      <div className="col-xl-6">
        <TransactionSide
          type="in"
          className="card-xl-stretch"
          highlightAddress={address}
          txs={tx.outputs || []}
        />
      </div>
    </div>
  )
}

const getTxAmount = (tx: Transaction, address: string) => {
  const outputSum =
    tx.outputs
      ?.filter((tx) => tx.scriptPublicKeyAddress === address)
      .map((tx) => Number(tx.amount) || 0)
      .reduce((a, b) => a + b, 0) || 0

  const inputSum =
    tx.inputs
      ?.filter((tx) => tx.previousOutput?.scriptPublicKeyAddress === address)
      .map((tx) => Number(tx.previousOutput.amount) || 0)
      .reduce((a, b) => a + b, 0) || 0

  return outputSum - inputSum
}

export const TransactionRow = ({
  tx,
  address,
  traceEnabled,
  loading,
}: TransactionProps) => {
  const intl = useIntl()
  const [expanded, { toggle: toggleExpand }] = useToggle()
  if (loading) {
    return (
      <div className="card-body border-top p-10 d-flex justify-content-between align-items-start">
        <Skeleton active />
      </div>
    )
  }

  const txAmount = parseFloat(getTxAmount(tx, address).toPrecision(8))

  let txTypeString
  let txTypeIconPath
  let txTypeIconClass

  if (isCompoundTransaction(tx)) {
    txTypeString = intl.formatMessage({ id: "COMPOUND" })
    txTypeIconPath = "43"
    txTypeIconClass = "svg-icon-1"
  } else {
    const type = txAmount >= 0 ? "in" : "out"
    txTypeString =
      type === "in"
        ? intl.formatMessage({ id: "RECEIVED" })
        : intl.formatMessage({ id: "SENT" })
    txTypeIconPath = type === "in" ? "41" : "42"
    txTypeIconClass = type === "in" ? "svg-icon-success" : "svg-icon-danger"
  }

  return (
    <>
      <div className="card-body p-5 d-flex justify-content-between align-items-start">
        <div className="ms-5 fs-6 fw-bold">
          <div className="d-flex align-items-center">
            <span className="svg-icon svg-icon-success me-2">
              <KTSVG
                path={`/media/icons/duotune/general/gen0${txTypeIconPath}.svg`}
                className={`svg-icon-2 ${txTypeIconClass}`}
              />
            </span>
            {txTypeString}
          </div>
          <div className="d-flex align-items-center flex-nowrap mt-1">
            <span className="me-2 text-nowrap">
              {formatKaspaAmount(Math.abs(txAmount))}
            </span>
            <FiatAmountBadge
              kaspaAmount={kaspaAmountToInt(Math.abs(txAmount))}
              className="ms-0"
            />
          </div>
        </div>
        <div className="fs-6 fw-bold text-end me-5 text-end">
          <TransactionStatusTag
            accepted={tx.isAccepted}
            confirmations={tx.confirmations}
            unknown={isLegacyTransaction(tx)}
          />
          <div className="fw-normal text-muted">
            {tx.blockTime &&
              moment.unix(tx.blockTime / 1e3).format("YYYY-MM-DD")}{" "}
            <div className="d-md-none"></div>
            {tx.blockTime && moment.unix(tx.blockTime / 1e3).format("HH:mm:ss")}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <TransactionIdLabel transactionId={tx.transactionId} />
        <i
          role="button"
          className="bi bi-three-dots fs-4 mx-10"
          onClick={toggleExpand}
        ></i>
      </div>

      {(traceEnabled || expanded) && (
        <TransactionTrace tx={tx} address={address} />
      )}
    </>
  )
}
