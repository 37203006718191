import { useIntl } from "react-intl"
import DraggableSelectionList from "../../../components/DraggableSelectionList"
import { availableDashboardMetricCards } from "../constants"
import { Card } from "../../../components/Card"

export const MetricsCustomizationModal = ({
  metricsKeys,
  onUpdated,
}: {
  metricsKeys: string[]
  onUpdated: (keys: string[]) => void
}) => {
  const intl = useIntl()

  const defaultDashboardMetricKeys = availableDashboardMetricCards({ intl })
    .map((i) => i.key)
    .slice(0, 8)

  const allAvailableCards = availableDashboardMetricCards({ intl })

  const allNodes = allAvailableCards
    .map((card) => {
      // Transform to node
      return { title: card.description, key: card.key }
    })
    .sort((a, b) => {
      // Sort all available nodes based on current selected cards
      const indexA = metricsKeys.indexOf(a.key)
      const indexB = metricsKeys.indexOf(b.key)
      if (indexA === -1) {
        return 1
      } else if (indexB === -1) {
        return -1
      } else {
        return indexA - indexB
      }
    })

  const onConfirmSelection = (keys: string[]) => {
    onUpdated(keys)
  }

  return (
    <Card
      title={intl.formatMessage({ id: "CUSTOMIZE_YOUR_DASHBOARD" })}
      body={
        <div className="d-flex flex-column align-items-center">
          <DraggableSelectionList
            nodes={allNodes}
            initialCheckedKeys={metricsKeys}
            onConfirm={onConfirmSelection}
            onClickReset={() => onConfirmSelection(defaultDashboardMetricKeys)}
          />
        </div>
      }
    ></Card>
  )
}
