import { useIntl } from "react-intl"
import { GetTokenSummaryResponse } from "../../../../client"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Line, LineConfig } from "@ant-design/plots"
import { Card } from "../../../components/Card"
import { formatUnixTime } from "../../../utils/utils"
import { UNIT_STRING } from "../../../constants/constants"
import { useAntVTheme } from "../../../hooks/useAntVTheme"
import { ChartIntervalSegment } from "./ChartIntervalSegment"
import { ChartLoading } from "../../../components/ChartLoading"

const TokenPriceChart = ({
  tokenData,
  chartInterval,
  onIntervalChange,
  loading,
}: {
  tokenData?: GetTokenSummaryResponse
  chartInterval: string
  onIntervalChange: (interval: string) => void
  loading?: boolean
}) => {
  const intl = useIntl()
  const priceHistory = tokenData?.priceHistory || []
  const theme = useAntVTheme()

  const config: LineConfig = {
    data: priceHistory,
    theme,
    xField: "t",
    yField: "p",
    smooth: true,
    animation: false,
    yAxis: {
      label: {
        formatter: (value) => {
          return `${value} ${UNIT_STRING}`
        },
      },
      min: Math.min(...priceHistory.map((i) => i.p)) * 0.95,
    },
    xAxis: {
      label: {
        formatter: (value) => {
          return formatUnixTime(parseInt(value) / 1000, false, "MM/DD HH:mm")
        },
      },
    },
    tooltip: {
      formatter: (value) => {
        return {
          title: formatUnixTime(parseInt(value.t) / 1000, false, "MM/DD HH:mm"),
          name: intl.formatMessage(
            {
              id: "TOKEN.PRICE_CHART.PRICE",
            },
            {
              ticker: tokenData?.ticker,
            }
          ),
          value: `${value.p.toFixed(8)} ${UNIT_STRING}`,
        }
      },
    },
  }
  return (
    <div className="col-xl-12">
      <Card
        className="pb-8"
        title={intl.formatMessage({
          id: "TOKEN.HEADER.PRICE_CHART",
        })}
        toolbar={
          <ChartIntervalSegment
            chartInterval={chartInterval}
            onIntervalChange={onIntervalChange}
          />
        }
        body={
          <Line
            {...config}
            loading={loading}
            loadingTemplate={<ChartLoading />}
          />
        }
      />
    </div>
  )
}

export { TokenPriceChart }
