/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import { Table, TableColumnsType } from "antd"
import {
  GetTokenSocialLinksResponse,
  GetTokenSummaryResponse,
} from "../../../../client"
import { useIntl } from "react-intl"
import { toAbsoluteUrl } from "../../../../_metronic/helpers"

const SocialItem = ({ type, url }: { type: string; url: string }) => {
  const prefix = "/media/svg/brand-logos/"
  const typeToIcon: Record<string, string> = {
    twitter: `${prefix}twitter.svg`,
    discord: `${prefix}discord.svg`,
    telegram: `${prefix}telegram.svg`,
  }

  const typeToName: Record<string, string> = {
    twitter: "Twitter",
    discord: "Discord",
    telegram: "Telegram",
  }

  const urlRoot = url?.split("/")?.length > 2 ? url.split("/")[2] : url

  return (
    <div className="p-1" role="button">
      <div className="d-flex align-items-center">
        {typeToIcon[type] ? (
          <img
            className="w-20px h-20px me-2"
            src={toAbsoluteUrl(typeToIcon[type])}
            alt={type}
          />
        ) : (
          <i className="bi bi-link-45deg fs-1 w-20px h-20px me-2" />
        )}
        <div>
          <div className="d-flex align-items-center text-body fs-6 fw-bold gap-1">
            <span className="text-hover-primary">
              {typeToName[type] ? typeToName[type] : urlRoot}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

const columns = (): TableColumnsType<GetTokenSocialLinksResponse> => {
  return [
    {
      render: (socialLink: GetTokenSocialLinksResponse) => {
        return (
          <a href={socialLink.url} target="_blank">
            <SocialItem type={socialLink.type} url={socialLink.url} />
          </a>
        )
      },
    },
  ]
}

const TokenSocials: React.FC<{
  socialLinks?: GetTokenSummaryResponse["socialLinks"]
}> = ({ socialLinks }) => {
  const intl = useIntl()

  const divWidth = 200

  return (
    <div className={`w-${divWidth}px`}>
      <Table
        columns={columns()}
        dataSource={socialLinks}
        size="small"
        showHeader={false}
        tableLayout="auto"
        scroll={{ y: 400 }}
        pagination={false}
      />
    </div>
  )
}

export { TokenSocials }
