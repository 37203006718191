/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { useIntl } from "react-intl"
import { TokenTransactionResponse } from "../../../client"
import { TokenTransactionRow } from "../token/components/TokenTransactionRow"

interface TokenTransactionBodyProps {
  tokenTransaction: TokenTransactionResponse
  loading?: boolean
}

const TokenTransactionBody: React.FC<TokenTransactionBodyProps> = ({
  tokenTransaction,
}) => {
  const intl = useIntl()

  return (
    <div className={"card mb-5 mb-xl-10"}>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1 d-flex align-items-center">
            {intl.formatMessage({ id: "TRANSACTION.TOKEN_TRANSFER.TITLE" })}
          </span>
          <span className="text-muted mt-1 fw-semibold fs-7">
            {intl.formatMessage(
              { id: "TRANSACTION.TOKEN_TRANSFER.DESCRIPTION" },
              {
                protocol: tokenTransaction.protocol,
              }
            )}
          </span>
        </h3>
      </div>

      <div className="py-3">
        <TokenTransactionRow
          decimal={tokenTransaction?.tokenInfo?.decimal || 8}
          tx={tokenTransaction}
          address={""}
          traceEnabled={false}
        />
      </div>
    </div>
  )
}

export { TokenTransactionBody }
