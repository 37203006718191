export const DEFLATIONARY_TABLE: { [key: number]: number } = {
  15519600: 500.0,
  18149400: 440.0,
  20779200: 415.30469757,
  23409000: 391.99543598,
  26038800: 369.99442271,
  28668600: 349.22823143,
  31298400: 329.62755691,
  33928200: 311.12698372,
  36558000: 293.66476791,
  39187800: 277.18263097,
  41817600: 261.6255653,
  44447400: 246.94165062,
  47077200: 233.08188075,
  49707000: 220.0,
  52336800: 207.65234878,
  54966600: 195.99771799,
  57596400: 184.99721135,
  60226200: 174.61411571,
  62856000: 164.81377845,
  65485800: 155.56349186,
  68115600: 146.83238395,
  70745400: 138.59131548,
  73375200: 130.81278265,
  76005000: 123.47082531,
  78634800: 116.54094037,
  81264600: 110.0,
  83894400: 103.82617439,
  86524200: 97.99885899,
  89154000: 92.49860567,
  91783800: 87.30705785,
  94413600: 82.40688922,
  97043400: 77.78174593,
  99673200: 73.41619197,
  102303000: 69.29565774,
  104932800: 65.40639132,
  107562600: 61.73541265,
  110192400: 58.27047018,
  112822200: 55.0,
  115452000: 51.91308719,
  118081800: 48.99942949,
  120711600: 46.24930283,
  123341400: 43.65352892,
  125971200: 41.20344461,
  128601000: 38.89087296,
  131230800: 36.70809598,
  133860600: 34.64782887,
  136490400: 32.70319566,
  139120200: 30.86770632,
  141750000: 29.13523509,
  144379800: 27.5,
  147009600: 25.95654359,
  149639400: 24.49971474,
  152269200: 23.12465141,
  154899000: 21.82676446,
  157528800: 20.6017223,
  160158600: 19.44543648,
  162788400: 18.35404799,
  165418200: 17.32391443,
  168048000: 16.35159783,
  170677800: 15.43385316,
  173307600: 14.56761754,
  175937400: 13.75,
  178567200: 12.97827179,
  181197000: 12.24985737,
  183826800: 11.5623257,
  186456600: 10.91338223,
  189086400: 10.30086115,
  191716200: 9.72271824,
  194346000: 9.17702399,
  196975800: 8.66195721,
  199605600: 8.17579891,
  202235400: 7.71692658,
  204865200: 7.28380877,
  207495000: 6.875,
  210124800: 6.48913589,
  212754600: 6.12492868,
  215384400: 5.78116285,
  218014200: 5.45669111,
  220644000: 5.15043057,
  223273800: 4.86135912,
  225903600: 4.58851199,
  228533400: 4.3309786,
  231163200: 4.08789945,
  233793000: 3.85846329,
  236422800: 3.64190438,
  239052600: 3.4375,
  241682400: 3.24456794,
  244312200: 3.06246434,
  246942000: 2.89058142,
  249571800: 2.72834555,
  252201600: 2.57521528,
  254831400: 2.43067956,
  257461200: 2.29425599,
  260091000: 2.1654893,
  262720800: 2.04394972,
  265350600: 1.92923164,
  267980400: 1.82095219,
  270610200: 1.71875,
  273240000: 1.62228397,
  275869800: 1.53123217,
  278499600: 1.44529071,
  281129400: 1.36417277,
  283759200: 1.28760764,
  286389000: 1.21533978,
  289018800: 1.14712799,
  291648600: 1.08274465,
  294278400: 1.02197486,
  296908200: 0.96461582,
  299538000: 0.91047609,
  302167800: 0.859375,
  304797600: 0.81114198,
  307427400: 0.76561608,
  310057200: 0.72264535,
  312687000: 0.68208638,
  315316800: 0.64380382,
  317946600: 0.60766989,
  320576400: 0.57356399,
  323206200: 0.54137232,
  325836000: 0.51098743,
  328465800: 0.48230791,
  331095600: 0.45523804,
  333725400: 0.4296875,
  336355200: 0.40557099,
  338985000: 0.38280804,
  341614800: 0.36132267,
  344244600: 0.34104319,
  346874400: 0.32190191,
  349504200: 0.30383494,
  352134000: 0.28678199,
  354763800: 0.27068616,
  357393600: 0.25549371,
  360023400: 0.24115395,
  362653200: 0.22761902,
  365283000: 0.21484375,
  367912800: 0.20278549,
  370542600: 0.19140402,
  373172400: 0.18066133,
  375802200: 0.17052159,
  378432000: 0.16095095,
  381061800: 0.15191747,
  383691600: 0.14339099,
  386321400: 0.13534308,
  388951200: 0.12774685,
  391581000: 0.12057697,
  394210800: 0.11380951,
  396840600: 0.10742187,
  399470400: 0.10139274,
  402100200: 0.09570201,
  404730000: 0.09033066,
  407359800: 0.08526079,
  409989600: 0.08047547,
  412619400: 0.07595873,
  415249200: 0.07169549,
  417879000: 0.06767154,
  420508800: 0.06387342,
  423138600: 0.06028848,
  425768400: 0.05690475,
  428398200: 0.05371093,
  431028000: 0.05069637,
  433657800: 0.047851,
  436287600: 0.04516533,
  438917400: 0.04263039,
  441547200: 0.04023773,
  444177000: 0.03797936,
  446806800: 0.03584774,
  449436600: 0.03383577,
  452066400: 0.03193671,
  454696200: 0.03014424,
  457326000: 0.02845237,
  459955800: 0.02685546,
  462585600: 0.02534818,
  465215400: 0.0239255,
  467845200: 0.02258266,
  470475000: 0.02131519,
  473104800: 0.02011886,
  475734600: 0.01898968,
  478364400: 0.01792387,
  480994200: 0.01691788,
  483624000: 0.01596835,
  486253800: 0.01507212,
  488883600: 0.01422618,
  491513400: 0.01342773,
  494143200: 0.01267409,
  496773000: 0.01196275,
  499402800: 0.01129133,
  502032600: 0.01065759,
  504662400: 0.01005943,
  507292200: 0.00949484,
  509922000: 0.00896193,
  512551800: 0.00845894,
  515181600: 0.00798417,
  517811400: 0.00753606,
  520441200: 0.00711309,
  523071000: 0.00671386,
  525700800: 0.00633704,
  528330600: 0.00598137,
  530960400: 0.00564566,
  533590200: 0.00532879,
  536220000: 0.00502971,
  538849800: 0.00474742,
  541479600: 0.00448096,
  544109400: 0.00422947,
  546739200: 0.00399208,
  549369000: 0.00376803,
  551998800: 0.00355654,
  554628600: 0.00335693,
  557258400: 0.00316852,
  559888200: 0.00299068,
  562518000: 0.00282283,
  565147800: 0.00266439,
  567777600: 0.00251485,
  570407400: 0.00237371,
  573037200: 0.00224048,
  575667000: 0.00211473,
  578296800: 0.00199604,
  580926600: 0.00188401,
  583556400: 0.00177827,
  586186200: 0.00167846,
  588816000: 0.00158426,
  591445800: 0.00149534,
  594075600: 0.00141141,
  596705400: 0.00133219,
  599335200: 0.00125742,
  601965000: 0.00118685,
  604594800: 0.00112024,
  607224600: 0.00105736,
  609854400: 0.00099802,
  612484200: 0.000942,
  615114000: 0.00088913,
  617743800: 0.00083923,
  620373600: 0.00079213,
  623003400: 0.00074767,
  625633200: 0.0007057,
  628263000: 0.00066609,
  630892800: 0.00062871,
  633522600: 0.00059342,
  636152400: 0.00056012,
  638782200: 0.00052868,
  641412000: 0.00049901,
  644041800: 0.000471,
  646671600: 0.00044456,
  649301400: 0.00041961,
  651931200: 0.00039606,
  654561000: 0.00037383,
  657190800: 0.00035285,
  659820600: 0.00033304,
  662450400: 0.00031435,
  665080200: 0.00029671,
  667710000: 0.00028006,
  670339800: 0.00026434,
  672969600: 0.0002495,
  675599400: 0.0002355,
  678229200: 0.00022228,
  680859000: 0.0002098,
  683488800: 0.00019803,
  686118600: 0.00018691,
  688748400: 0.00017642,
  691378200: 0.00016652,
  694008000: 0.00015717,
  696637800: 0.00014835,
  699267600: 0.00014003,
  701897400: 0.00013217,
  704527200: 0.00012475,
  707157000: 0.00011775,
  709786800: 0.00011114,
  712416600: 0.0001049,
  715046400: 9.901e-5,
  717676200: 9.345e-5,
  720306000: 8.821e-5,
  722935800: 8.326e-5,
  725565600: 7.858e-5,
  728195400: 7.417e-5,
  730825200: 7.001e-5,
  733455000: 6.608e-5,
  736084800: 6.237e-5,
  738714600: 5.887e-5,
  741344400: 5.557e-5,
  743974200: 5.245e-5,
  746604000: 4.95e-5,
  749233800: 4.672e-5,
  751863600: 4.41e-5,
  754493400: 4.163e-5,
  757123200: 3.929e-5,
  759753000: 3.708e-5,
  762382800: 3.5e-5,
  765012600: 3.304e-5,
  767642400: 3.118e-5,
  770272200: 2.943e-5,
  772902000: 2.778e-5,
  775531800: 2.622e-5,
  778161600: 2.475e-5,
  780791400: 2.336e-5,
  783421200: 2.205e-5,
  786051000: 2.081e-5,
  788680800: 1.964e-5,
  791310600: 1.854e-5,
  793940400: 1.75e-5,
  796570200: 1.652e-5,
  799200000: 1.559e-5,
  801829800: 1.471e-5,
  804459600: 1.389e-5,
  807089400: 1.311e-5,
  809719200: 1.237e-5,
  812349000: 1.168e-5,
  814978800: 1.102e-5,
  817608600: 1.04e-5,
  820238400: 9.82e-6,
  822868200: 9.27e-6,
  825498000: 8.75e-6,
  828127800: 8.26e-6,
  830757600: 7.79e-6,
  833387400: 7.35e-6,
  836017200: 6.94e-6,
  838647000: 6.55e-6,
  841276800: 6.18e-6,
  843906600: 5.84e-6,
  846536400: 5.51e-6,
  849166200: 5.2e-6,
  851796000: 4.91e-6,
  854425800: 4.63e-6,
  857055600: 4.37e-6,
  859685400: 4.13e-6,
  862315200: 3.89e-6,
  864945000: 3.67e-6,
  867574800: 3.47e-6,
  870204600: 3.27e-6,
  872834400: 3.09e-6,
  875464200: 2.92e-6,
  878094000: 2.75e-6,
  880723800: 2.6e-6,
  883353600: 2.45e-6,
  885983400: 2.31e-6,
  888613200: 2.18e-6,
  891243000: 2.06e-6,
  893872800: 1.94e-6,
  896502600: 1.83e-6,
  899132400: 1.73e-6,
  901762200: 1.63e-6,
  904392000: 1.54e-6,
  907021800: 1.46e-6,
  909651600: 1.37e-6,
  912281400: 1.3e-6,
  914911200: 1.22e-6,
  917541000: 1.15e-6,
  920170800: 1.09e-6,
  922800600: 1.03e-6,
  925430400: 9.7e-7,
  928060200: 9.1e-7,
  930690000: 8.6e-7,
  933319800: 8.1e-7,
  935949600: 7.7e-7,
  938579400: 7.3e-7,
  941209200: 6.8e-7,
  943839000: 6.5e-7,
  946468800: 6.1e-7,
  949098600: 5.7e-7,
  951728400: 5.4e-7,
  954358200: 5.1e-7,
  956988000: 4.8e-7,
  959617800: 4.5e-7,
  962247600: 4.3e-7,
  964877400: 4e-7,
  967507200: 3.8e-7,
  970137000: 3.6e-7,
  972766800: 3.4e-7,
  975396600: 3.2e-7,
  978026400: 3e-7,
  980656200: 2.8e-7,
  983286000: 2.7e-7,
  985915800: 2.5e-7,
  988545600: 2.4e-7,
  991175400: 2.2e-7,
  993805200: 2.1e-7,
  996435000: 2e-7,
  999064800: 1.9e-7,
  1001694600: 1.8e-7,
  1004324400: 1.7e-7,
  1006954200: 1.6e-7,
  1009584000: 1.5e-7,
  1012213800: 1.4e-7,
  1014843600: 1.3e-7,
  1017473400: 1.2e-7,
  1020103200: 1.2e-7,
  1022733000: 1.1e-7,
  1025362800: 1e-7,
  1027992600: 1e-7,
  1030622400: 9e-8,
  1033252200: 9e-8,
  1035882000: 8e-8,
  1038511800: 8e-8,
  1041141600: 7e-8,
  1043771400: 7e-8,
  1046401200: 6e-8,
  1049031000: 6e-8,
  1051660800: 6e-8,
  1054290600: 5e-8,
  1056920400: 5e-8,
  1059550200: 5e-8,
  1062180000: 4e-8,
  1064809800: 4e-8,
  1067439600: 4e-8,
  1070069400: 4e-8,
  1072699200: 3e-8,
  1075329000: 3e-8,
  1077958800: 3e-8,
  1080588600: 3e-8,
  1083218400: 3e-8,
  1085848200: 2e-8,
  1088478000: 2e-8,
  1091107800: 2e-8,
  1093737600: 2e-8,
  1096367400: 2e-8,
  1098997200: 2e-8,
  1101627000: 2e-8,
  1104256800: 1e-8,
  1106886600: 1e-8,
  1109516400: 1e-8,
  1112146200: 1e-8,
  1114776000: 1e-8,
  1117405800: 1e-8,
  1120035600: 1e-8,
  1122665400: 1e-8,
  1125295200: 1e-8,
  1127925000: 1e-8,
  1130554800: 1e-8,
  1133184600: 1e-8,
  9223372036854775807: 0.0,
}
