/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Block } from '../models/Block';
import type { Transaction } from '../models/Transaction';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BlocksService {

    /**
     * Return header details about a Kaspa block
     * @param hash Hash of a Kaspa block
     * @returns Block
     * @throws ApiError
     */
    public static blocksControllerGetBlockInfo(
        hash: string,
    ): CancelablePromise<Block> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/blocks/{hash}',
            path: {
                'hash': hash,
            },
        });
    }

    /**
     * Return list of transactions in a Kaspa block
     * @param hash Hash of a Kaspa block
     * @returns Transaction
     * @throws ApiError
     */
    public static blocksControllerGetBlockTransaction(
        hash: string,
    ): CancelablePromise<Array<Transaction>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/blocks/{hash}/transactions',
            path: {
                'hash': hash,
            },
        });
    }

}
