/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/rules-of-hooks */
import { useLocalStorageState } from "ahooks"
import { useIntl } from "react-intl"
import { DashboardMetricsResponse } from "../../../../client"
import { dashboardMetricsKey } from "../../../constants/localStorageKeys"

import { availableDashboardMetricCards } from "../constants"
import { MetricCard } from "./MetricCard"
import { MetricsCustomizationModal } from "./MetricsCustomizationModal"
import { useGlobalData } from "../../../utils/GlobalDataProvider"
import { useEffect, useMemo, useState } from "react"
import { Modal } from "antd"
import { AdsAlertBody } from "../../../components/partials/AdsAlert"

export const metricsCardClass = `col-xl-3 col-6 mb-5 mb-xl-0`

export const TopMetricsSection = () => {
  const intl = useIntl()
  const { kaspadSocket } = useGlobalData()

  const [modalOpen, setModalOpen] = useState(false)
  const [stagingData, setStagingData] = useState<
    DashboardMetricsResponse | undefined
  >(undefined)

  useEffect(() => {
    function connectSocket() {
      if (!kaspadSocket.connected) {
        kaspadSocket.connect()
      }

      function onConnect() {
        kaspadSocket.emit("join-room", "dashboard")
      }

      function onDashboardMetrics(data: DashboardMetricsResponse) {
        setStagingData(data)
      }

      kaspadSocket.on("connect", onConnect)
      kaspadSocket.on("dashboard-metrics", onDashboardMetrics)

      if (kaspadSocket.connected) {
        kaspadSocket.emit("join-room", "dashboard")
      }

      return () => {
        kaspadSocket.off("connect", onConnect)
        kaspadSocket.off("dashboard-metrics", onDashboardMetrics)
        kaspadSocket.emit("leave-room", "dashboard")
      }
    }

    const disconnect = connectSocket()

    return () => {
      disconnect()
    }
  }, [kaspadSocket])

  const defaultDashboardMetricKeys = availableDashboardMetricCards({ intl })
    .map((i) => i.key)
    .slice(0, 8)
  const [dashboardMetricKeys, setDashboardMetricKeys] = useLocalStorageState<
    string[]
  >(dashboardMetricsKey, {
    defaultValue: defaultDashboardMetricKeys,
  })

  const allAvailableCards = availableDashboardMetricCards({
    intl,
    data: stagingData,
  })

  const metricCards = dashboardMetricKeys
    ?.map((key) => allAvailableCards.filter((card) => card.key === key))
    .flat()

  const TopNotificationBar = useMemo(() => {
    return (
      <div className="col-xl-12 mb-5">
        <div className="alert bg-body col-12 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <span className="badge badge-danger me-3 me-md-2">HOT</span>
            <span>
              Check out our new{" "}
              <a href="/krc20-tokens">KRC20 Token Dashboard</a>!
            </span>
          </div>
          <i
            role="button"
            className="bi bi-grid fs-3 me-2 text-muted ps-5"
            onClick={() => setModalOpen(true)}
          ></i>
          <Modal
            destroyOnClose
            open={modalOpen}
            footer={null}
            wrapClassName={"top-metrics-modal"}
            onCancel={() => setModalOpen(false)}
          >
            <MetricsCustomizationModal
              metricsKeys={dashboardMetricKeys ?? []}
              onUpdated={(keys: string[]) => {
                setDashboardMetricKeys(keys)
                setModalOpen(false)
              }}
            />
          </Modal>
        </div>
      </div>
    )
  }, [dashboardMetricKeys, modalOpen, setDashboardMetricKeys])

  return (
    <div className="col-xl-8">
      {TopNotificationBar}
      <div className="col-xl-12">
        <div className="row">
          {metricCards?.map((row) => {
            return (
              <div className={metricsCardClass}>
                <MetricCard
                  className="bg-body card-xl-stretch mb-xl-8"
                  titleClass="text-dark"
                  descriptionClass="text-muted"
                  iconClass="svg-icon-primary"
                  {...row}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
