import { MenuItem } from "./MenuItem"
import { MenuInnerWithSub } from "./MenuInnerWithSub"
import { useIntl } from "react-intl"

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({ id: "MENU.DASHBOARD" })} to="/" />
      <MenuItem
        title={intl.formatMessage({ id: "MENU.KRC20_TOKENS" })}
        to="/krc20-tokens"
        fontIcon="bi-rocket-takeoff text-primary"
      />
      <MenuItem
        title={intl.formatMessage({ id: "MENU.TOP_ADDRESSES" })}
        to="/top-addresses"
      />
      <MenuItem
        title={intl.formatMessage({ id: "TOOLS.DEFLATIONARY_TABLE.TITLE" })}
        to="/tools/emission-schedule"
      />
      {/* <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.TOOLS" })}
        to="/tools"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="/tools/emission-schedule"
          title={intl.formatMessage({ id: "TOOLS.DEFLATIONARY_TABLE.TITLE" })}
          hasBullet={true}
        />
      </MenuInnerWithSub> */}

      <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.COMMUNITY_TOOLS" })}
        to="#"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="https://kaspa-transaction-report.vercel.app/"
          title={intl.formatMessage({ id: "TOOLS.TX_REPORT.TITLE" })}
          hasBullet={true}
          newWindow
        />
        <MenuItem
          to="https://kas-breakeven-calc.vercel.app/"
          title={intl.formatMessage({ id: "TOOLS.MINING_BE_CALC.TITLE" })}
          hasBullet={true}
          newWindow
        />
        <MenuItem
          to="https://www.kaspalytics.com/"
          title={"Kaspalytics"}
          hasBullet={true}
          newWindow
        />
      </MenuInnerWithSub>

      <MenuItem
        to="#"
        title={intl.formatMessage({ id: "DONATION_LINK.NAME" })}
        modalTarget="#donation-address-modal"
      />
    </>
  )
}
