import { IntlShape } from "react-intl"
import { AddressTag } from "../../../client"
import { kaspaAmountToInt } from "../../utils/utils"

export const getAddressBalanceCategory = (
  intl: IntlShape,
  balance?: number
): AddressTag | undefined => {
  if (!balance || balance <= 0) return undefined

  const intAmonut = Math.floor(kaspaAmountToInt(balance))
  const index = intAmonut < 100 ? 2 : intAmonut.toString().length

  return {
    address: "",
    link: "/top-addresses",
    name: intl.formatMessage({
      id: `HOLDERS.ADDRESSES_DISTRIBUTION.CATEGORY.${index}`,
    }),
    type: AddressTag.type.RANK,
  }
}
