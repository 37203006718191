import { Popover, QRCode } from "antd"
import { useState } from "react"
import { useIntl } from "react-intl"

export const QrIcon = ({ value }: { value?: string }) => {
  const intl = useIntl()
  const [, setCopied] = useState(false)

  const onClick = (value: string) => {
    navigator.clipboard.writeText(value)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  const popoverContent = () => {
    return value && <QRCode size={140} bordered={false} value={value} />
  }

  return (
    <>
      <Popover overlayInnerStyle={{ padding: 0 }} content={popoverContent}>
        <i
          role="button"
          data-bs-toggle="modal"
          data-bs-target="#address-QR-modal"
          onClick={() => value && onClick(value)}
          className="las la-qrcode text-hover-primary fs-2 mx-1"
        ></i>
      </Popover>
    </>
  )
}
