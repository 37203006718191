/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MarketDataResponse } from '../models/MarketDataResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MarketDataService {

    /**
     * Return Kaspa market data
     * @returns MarketDataResponse
     * @throws ApiError
     */
    public static marketControllerGetMarketData(): CancelablePromise<MarketDataResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/market',
        });
    }

}
