/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { useIntl } from "react-intl"
import {
  AddressTag,
  TransactionInput,
  TransactionOutput,
} from "../../../client"
import { toAbsoluteUrl } from "../../../_metronic/helpers"
import { FiatAmountBadge } from "../../components/FiatAmountBadge"
import { PasteIcon } from "../../components/PasteIcon"
import { getAddressRoute, getTransactionRoute } from "../../hooks/navigator"
import { formatKaspaAmount, kaspaAmountToInt } from "../../utils/utils"
import { Typography } from "antd"
import { Popover, Skeleton, Tooltip } from "antd"
import { parseKrc20Script } from "../../utils/inscription"
import { Link } from "react-router-dom"

const { Text } = Typography

type TransactionType = "in" | "out"

type Props = {
  className: string
  type: TransactionType
  txs?: Array<TransactionInput> | Array<TransactionOutput>
  showTrace?: boolean
  highlightAddress?: string
  loading?: boolean
}

interface CellProps {
  amount: number | undefined
  address: string
  addressTag?: AddressTag
  highlighted?: boolean
  traceTxId?: string
  type: TransactionType
  debugTooltip: React.ReactNode
  missingSender?: boolean
}

const TransactionDebuggingTooltip = ({
  tx,
}: {
  tx: TransactionInput | TransactionOutput
}) => {
  const isInput = (tx as TransactionInput).previousOutpointHash !== undefined
  const Row = ({ title, value }: { title: string; value: any }) => (
    <div className="lh-md mb-2">
      <div>
        <Text strong className="text-break">
          {title}
        </Text>
      </div>
      <div>
        <Text code className="fs-7 ms-n2">
          {value}
        </Text>
      </div>
    </div>
  )

  let content

  if (isInput) {
    const input = tx as TransactionInput
    const parsedKrc20Script = parseKrc20Script(input.signatureScript)
    content = (
      <div>
        <Row
          title="Previous Outpoint Hash"
          value={input.previousOutpointHash}
        />
        <Row
          title="Previous Outpoint Index"
          value={input.previousOutpointIndex}
        />
        <Row title="Signature Script" value={input.signatureScript} />
        {parsedKrc20Script ? (
          <Row
            title="Signature Script Parsed (KRC-20)"
            value={parsedKrc20Script}
          />
        ) : null}
        <Row title="Signature Op Count" value={input.sigOpCount} />
      </div>
    )
  } else {
    const output = tx as TransactionOutput
    content = (
      <div>
        <Row title="Index" value={output.index} />
        <Row
          title="Script Public Key Type"
          value={output.scriptPublicKeyType}
        />
        <Row title="Script Public Key" value={output.scriptPublicKey} />
      </div>
    )
  }

  return (
    <Popover content={content}>
      <i role="button" className="fa-solid fa-code text-hover-primary fs-7"></i>
    </Popover>
  )
}

const TraceIcon = ({ txId, type }: { txId: string; type: TransactionType }) => {
  const icon = type === "in" ? "fa-circle-right ms-1" : "fa-circle-left me-3"
  return (
    <Link to={getTransactionRoute(txId)} className="d-flex align-items-center">
      <i role="button" className={`fa-regular ${icon} text-hover-primary`}></i>
    </Link>
  )
}

const Cell = ({
  amount,
  address,
  addressTag,
  highlighted,
  type,
  traceTxId,
  debugTooltip,
  missingSender,
}: CellProps) => {
  const intl = useIntl()

  const addressTagText = addressTag ? (
    <Tooltip title={`${address}`}>
      {addressTag.name}
      <span className="text-gray-500 ms-1">
        ({addressTag.address.slice(-4)})
      </span>
    </Tooltip>
  ) : undefined

  const sign = type === "in" ? "+" : "-"
  return (
    <div
      className={`d-flex align-items-center ${
        highlighted ? "bg-light-primary" : "bg-light"
      } rounded p-5 mb-7`}
    >
      <Tooltip
        title={
          missingSender
            ? intl.formatMessage({ id: "TRANSACTION.STATUS.UNKNOWN.TOOLTIP" })
            : ""
        }
      >
        <div className="flex-grow-1 me-2">
          <Link
            role="button"
            className="fw-bold text-gray-800 text-hover-primary fs-6 text-break"
            to={missingSender ? "#" : getAddressRoute(address)}
          >
            {addressTagText || address}
          </Link>
          <PasteIcon value={address} />
          {debugTooltip}
          <div className="d-flex align-items-center mt-3">
            {traceTxId && type === "out" && (
              <TraceIcon txId={traceTxId} type={type} />
            )}
            <span
              className={`badge ${
                type === "in" ? "badge-success" : "badge-danger"
              }`}
            >
              {amount !== undefined
                ? `${sign}` + formatKaspaAmount(amount)
                : "?"}
            </span>
            {amount && (
              <FiatAmountBadge kaspaAmount={kaspaAmountToInt(amount)} />
            )}

            {traceTxId && type === "in" && (
              <TraceIcon txId={traceTxId} type={type} />
            )}
          </div>
        </div>
      </Tooltip>
    </div>
  )
}

const CoinbaseCell = () => {
  const intl = useIntl()
  return (
    <div className={`d-flex align-items-center bg-light rounded p-5 mb-7`}>
      <div className="flex-grow-1 me-2">
        {intl.formatMessage({ id: "NEW_COINS" })}
      </div>
    </div>
  )
}

const ZeroOutputCell = () => {
  const intl = useIntl()
  return (
    <div className={`d-flex align-items-center bg-light rounded p-5 mb-7`}>
      <div className="flex-grow-1 me-2">
        {intl.formatMessage({ id: "ZERO_OUTPUT" })}
      </div>
    </div>
  )
}

const TransactionSide: React.FC<Props> = ({
  className,
  type,
  txs,
  highlightAddress,
  showTrace = true,
  loading,
}) => {
  const intl = useIntl()
  const title =
    type === "in"
      ? intl.formatMessage({ id: "RECIPIENTS" })
      : intl.formatMessage({ id: "SENDERS" })

  const isZeroOutput = type === "in" && txs?.length === 0

  return (
    <div
      className={`${className} card card-xl-stretch mb-3 mb-xl-3 bgi-no-repeat`}
      style={{
        backgroundPosition: "right top",
        backgroundSize: "15% auto",
        backgroundImage: `url(${toAbsoluteUrl(
          "/media/svg/shapes/abstract-2.svg"
        )})`,
      }}
    >
      <div className="card-header border-0">
        <h3 className="card-title fw-bold text-dark">
          {title} {txs?.length ? `(${txs?.length})` : null}
        </h3>
      </div>
      <div className="card-body pt-0">
        {loading ? (
          <Skeleton active />
        ) : txs && txs.length > 0 ? (
          txs.map((tx: TransactionInput | TransactionOutput, index: number) => {
            const amount =
              (tx as TransactionInput).previousOutput?.amount ||
              (tx as TransactionOutput).amount
            const scriptPublicKeyAddress =
              (tx as TransactionInput).previousOutput?.scriptPublicKeyAddress ||
              (tx as TransactionOutput).scriptPublicKeyAddress ||
              (tx as TransactionInput).signatureScript
            const addressTag =
              (tx as TransactionInput).previousOutput
                ?.scriptPublicKeyAddressTag ||
              (tx as TransactionOutput).scriptPublicKeyAddressTag
            const traceTxId = showTrace
              ? (tx as TransactionInput).previousOutpointHash ||
                (tx as TransactionOutput).spentTxn
              : undefined

            // If we don't have the sender address, we use the signature script
            const missingSender =
              scriptPublicKeyAddress ===
              (tx as TransactionInput).signatureScript

            return (
              <Cell
                key={`tx-cell-${index}`}
                amount={amount}
                address={scriptPublicKeyAddress}
                addressTag={addressTag}
                highlighted={scriptPublicKeyAddress === highlightAddress}
                traceTxId={traceTxId}
                debugTooltip={<TransactionDebuggingTooltip tx={tx} />}
                type={type}
                missingSender={missingSender}
              ></Cell>
            )
          })
        ) : isZeroOutput ? (
          <ZeroOutputCell />
        ) : (
          <CoinbaseCell />
        )}
      </div>
    </div>
  )
}

export { TransactionSide }
