/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRequest } from "ahooks"
import { useMemo, useRef, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { TokenService } from "../../../client"
import { TITLE_SUFFIX } from "../../constants/constants"
import { HelmetWrapper } from "../../utils/HelmetWrappper"
import { TokenHeader } from "./components/TokenHeader"
import { AdsPlacement } from "../../components/Ads/AdsPlacement"
import { TokenTransactionsWrapper } from "./TokenTransactionsWrapper"
import { TransactionViewType } from "../address/components/AddressTransactions"
import { Divider, Segmented } from "antd"
import {
  SwapOutlined,
  UserOutlined,
  LineChartOutlined,
} from "@ant-design/icons"
import { TokenHoldersList } from "./components/TokenHoldersList"
import { useIntl } from "react-intl"
import { TokenPriceChart } from "./components/TokenPriceChart"
import { TokenStatsChart } from "./components/TokenStatsChart"
import { TokenMarketList } from "./components/TokenMarketList"
import { getRandomAdsPlatfrom } from "../../utils/utils"

const mobileBottomMarginClass = "mb-5 mb-xl-0"
const row = `row g-xl-8 ${mobileBottomMarginClass} `

const TokenComponent = ({ ticker }: { ticker: string }) => {
  const intl = useIntl()
  const transactionsRef = useRef<null | HTMLDivElement>(null)
  const [adsPlatform] = useState(() => getRandomAdsPlatfrom())

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const requestedViewType = searchParams.get("view") as TransactionViewType

  const [viewType, setViewType] = useState<TransactionViewType>(
    requestedViewType || TransactionViewType.TokenPriceChart
  )
  const [chartInterval, setChartInterval] = useState<string>("7d")

  const { data: tokenSummary, loading: tokenSummaryLoading } = useRequest(
    async () =>
      await TokenService.tokenControllerGetTokenSummary(
        ticker,
        true,
        chartInterval
      ),
    {
      refreshDeps: [chartInterval],
    }
  )

  const Body = useMemo(() => {
    switch (viewType) {
      case TransactionViewType.TokenTransfer:
        return (
          <TokenTransactionsWrapper
            ticker={ticker}
            hideHeader
            transactionsCardRef={transactionsRef}
          />
        )
      case TransactionViewType.TokenHolders:
        return (
          <TokenHoldersList
            tokenData={tokenSummary}
            tokenHolders={tokenSummary?.holders}
            loading={tokenSummaryLoading}
          />
        )
      case TransactionViewType.TokenPriceChart:
        return (
          <>
            <TokenPriceChart
              tokenData={tokenSummary}
              chartInterval={chartInterval}
              onIntervalChange={setChartInterval}
              loading={tokenSummaryLoading}
            />
            <Divider dashed />
            <TokenMarketList
              tokenData={tokenSummary}
              loading={tokenSummaryLoading}
            />
            <Divider dashed />
            <TokenStatsChart
              tokenData={tokenSummary}
              chartInterval={chartInterval}
              onIntervalChange={setChartInterval}
              loading={tokenSummaryLoading}
            />
          </>
        )
      default:
        return null
    }
  }, [viewType, ticker, tokenSummary, chartInterval, tokenSummaryLoading])

  return (
    <div className={row}>
      <HelmetWrapper
        title={`KRC20 Token ${ticker} ${TITLE_SUFFIX}`}
        description={`Details of Kaspa KRC20 Token ${ticker}`}
      />
      <div className={"col-xl-4"}>
        <TokenHeader token={tokenSummary} loading={tokenSummaryLoading} />
      </div>

      <div ref={transactionsRef} className={"col-xl-8"}>
        <div className={"card mb-5 mb-xl-10"} style={{ minHeight: "10vh" }}>
          <div className="card-header border-bottom">
            <div className="card-title m-0 d-flex align-items-center">
              <Segmented
                value={viewType}
                onChange={(value) => {
                  setViewType(value as TransactionViewType)
                  searchParams.set("view", value as string)
                  const newUrl = `${
                    window.location.pathname
                  }?${searchParams.toString()}`
                  window.history.pushState(null, "", newUrl)
                }}
                options={[
                  {
                    label: intl.formatMessage({
                      id: "TOKEN.HEADER.STATS",
                    }),
                    value: TransactionViewType.TokenPriceChart,
                    icon: <LineChartOutlined />,
                  },
                  {
                    label: intl.formatMessage({
                      id: "TOKEN.HEADER.TRANSFERS",
                    }),
                    value: TransactionViewType.TokenTransfer,
                    icon: <SwapOutlined />,
                  },
                  {
                    label: intl.formatMessage({
                      id: "TOKEN.HEADER.HOLDERS",
                    }),
                    value: TransactionViewType.TokenHolders,
                    icon: <UserOutlined />,
                  },
                ]}
              />
            </div>
          </div>

          {Body}
        </div>

        <div className="mt-5">
          <AdsPlacement placementType="banner" platform={adsPlatform} />
        </div>
      </div>
    </div>
  )
}

const TokenWrapper = () => {
  const { ticker = "" } = useParams()
  const tickerUpper = ticker.toUpperCase()

  return <TokenComponent key={tickerUpper} ticker={tickerUpper} />
}

export { TokenWrapper }
