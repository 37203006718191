/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTSVG, useDebounce } from "../../_metronic/helpers"
import { useState, ChangeEvent, useEffect } from "react"
import { NetworkDataService, SearchNetworkResult } from "../../client"
import { useIntl } from "react-intl"
import { Popover } from "antd"
import { SearchBarResults } from "./SearchBarResults"
import { SEARCH_BAR_MIN_QUERY_LENGTH } from "../constants/constants"

export const SearchBar = ({ width }: { width: number }) => {
  const intl = useIntl()
  const [query, setQuery] = useState<string>("")
  const debouncedQuery = useDebounce(query, 500)

  const [loading, setLoading] = useState<Boolean>()
  const [results, setResults] = useState<SearchNetworkResult[] | undefined>(
    undefined
  )
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    if (
      debouncedQuery &&
      debouncedQuery.length >= SEARCH_BAR_MIN_QUERY_LENGTH
    ) {
      search(debouncedQuery)
    }
  }, [debouncedQuery])

  async function search(query: string) {
    setLoading(true)
    const { results } = await NetworkDataService.networkControllerSearchNetwork(
      query
    )
    setLoading(false)
    setResults(results)
    setIsOpen(true)
  }

  const resetSearchResults = () => {
    setResults(undefined)
  }

  const clearInput = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()

    setQuery("")
    resetSearchResults()

    // Re-focus the input element
    const inputElement = document.querySelector(
      'input[name="search"]'
    ) as HTMLInputElement

    if (inputElement) {
      inputElement.focus()
    }
  }

  const onSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
    resetSearchResults()
  }

  const onSearchInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()
    }
  }

  return (
    <Popover
      overlayInnerStyle={{ padding: 1 }}
      autoAdjustOverflow={false}
      content={
        <div onClick={(e) => e.stopPropagation()}>
          <SearchBarResults
            query={debouncedQuery}
            width={width}
            results={results}
            onClose={() => setIsOpen(false)}
          />
        </div>
      }
      placement="bottom"
      arrow={false}
      open={isOpen}
      trigger="click"
      onOpenChange={(val) => setIsOpen(val)}
    >
      <form
        data-kt-search-element="form"
        className="w-100 position-relative"
        autoComplete="off"
      >
        <KTSVG
          path="/media/icons/duotune/general/gen021.svg"
          className="svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0"
        />

        <input
          type="text"
          className="form-control form-control-flush ps-10 pe-10"
          name="search"
          onChange={onSearchInputChange}
          value={query}
          placeholder={intl.formatMessage({ id: "SEARCH_BAR.PLACEHOLDER" })}
          data-kt-search-element="input"
          autoComplete="off"
          autoCorrect="off"
          onKeyDown={onSearchInputKeyDown}
        />

        <div
          className="position-absolute top-50 end-0 translate-middle-y"
          data-kt-search-element="toolbar"
        >
          {loading ? (
            <div
              className="spinner-border spinner-border-sm me-4 w-15px h-15px"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : query.length > 0 ? (
            <div
              data-kt-search-element="preferences-show"
              className="btn btn-icon w-20px btn-sm btn-active-color-primary me-4"
              data-bs-toggle="tooltip"
              onClick={clearInput}
              onMouseDown={(e) => e.preventDefault()}
            >
              <i className="bi bi-x-lg fs-2"></i>
            </div>
          ) : null}
        </div>
      </form>
    </Popover>
  )
}
