/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react"
import { TokenService } from "../../../client"
import { TokenTransactions } from "./components/TokenTransactions"
import { SimplePagination } from "../../components/partials/SimplePagination"

const TokenTransactionsWrapper = ({
  ticker,
  address,
  hideHeader,
  transactionsCardRef,
}: {
  ticker?: string
  address?: string
  hideHeader?: boolean
  transactionsCardRef?: React.RefObject<HTMLDivElement>
}) => {
  const [tokenTxns, setTokenTxns] = useState<any>({
    results: [],
    nextCursor: null,
    previousCursor: null,
  })
  const [tokenTxnsLoading, setTokenTxnsLoading] = useState<boolean>(true)
  const [reachedEnd, setReachedEnd] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)

  const requestTokenTxns = async (goBack: boolean, currentPage: number) => {
    if (reachedEnd && !goBack) return

    setTokenTxnsLoading(true)
    const resp = await TokenService.tokenControllerGetTokenTransactions(
      goBack ? null : tokenTxns.nextCursor,
      address,
      ticker
    )

    if (!resp.results.length) {
      setReachedEnd(true)
      setPage(currentPage - 1)
    } else {
      setTokenTxns({
        results: resp.results,
        nextCursor: resp.nextCursor,
        previousCursor: resp.previousCursor,
      })
    }

    setTokenTxnsLoading(false)
  }

  useEffect(() => {
    requestTokenTxns(false, page)
  }, [])

  return (
    <div>
      <TokenTransactions
        transactions={tokenTxns.results}
        address={address}
        loading={tokenTxnsLoading}
        hideHeader={hideHeader}
      />

      <div className="m-5">
        <SimplePagination
          currentPage={page}
          totalPage={reachedEnd ? page : page + 1}
          onPreviousPage={() => {
            setPage(1)
            setReachedEnd(false)
            requestTokenTxns(true, page)
          }}
          onNextPage={() => {
            setPage(page + 1)
            requestTokenTxns(false, page + 1)
          }}
          addressTnxLoading={tokenTxnsLoading}
          transactionsCardRef={transactionsCardRef}
          hideTotalPage
        />
      </div>
    </div>
  )
}

export { TokenTransactionsWrapper }
