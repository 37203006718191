import { Link } from "react-router-dom"
import { getBlockRoute } from "../hooks/navigator"

export const BlockHashBullet = ({
  className,
  hash,
}: {
  className?: string
  hash: string
}) => {
  return (
    <li className="d-flex align-items-center py-1">
      <span className="bullet bullet-dot bg-primary me-2"></span>
      <Link
        role="button"
        className={`${className} text-hover-primary text-break parent-block-hash`}
        to={getBlockRoute(hash)}
      >
        {hash}
      </Link>
    </li>
  )
}
