/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRequest } from "ahooks"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { request as __request } from "../../../../client/core/request"
import { Card } from "../../../components/Card"
import Map, { Marker } from "react-map-gl"
import { useThemeMode } from "../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider"
import { formatUnixTime } from "../../../utils/utils"
import { Tooltip } from "antd"

interface NodesResponse {
  nodes: {
    [key: string]: {
      loc: string
      kaspad: string
    }
  }
  updated_at: number
}

interface NodeLocation {
  lat: number
  lon: number
  version: string
}

const parseKaspadVersion = (version: string) => {
  const regex = /:([\d]+\.[\d]+\.[\d]+)/
  const match = version.match(regex)
  if (match && match[1]) {
    return match[1]
  }
  return null
}

const isRustyNode = (version?: string) => {
  const rustVersion = "0.13"
  if (!version) {
    return false
  }
  return version >= rustVersion
}

const MAPBOX_TOKEN =
  "pk.eyJ1IjoibHZ5dWFuOTUiLCJhIjoiY2xnb2IzNWc4MG8wOTNmb2U0MWFzeHd1dSJ9.Q9SfeqoJnYwdvuja-CiLww"

const NodeMapSection: React.FC = () => {
  const intl = useIntl()
  const { mode } = useThemeMode()
  const mapStyleUrl = `mapbox://styles/mapbox/${mode}-v11`

  const request = () => {
    return __request(
      {
        BASE: "https://kaspa-node-crawler.fly.dev",
        VERSION: "",
        WITH_CREDENTIALS: false,
        CREDENTIALS: "include",
      },
      {
        method: "GET",
        url: "/",
      }
    )
  }
  const { data } = useRequest(request)

  const locations = useMemo(() => {
    const convertedData = data as NodesResponse
    if (convertedData && Object.keys(convertedData.nodes).length > 0) {
      return Object.keys(convertedData.nodes)
        .map((i) => {
          if (convertedData.nodes[i]?.loc) {
            const cords = convertedData.nodes[i].loc.split(",")
            const version = parseKaspadVersion(convertedData.nodes[i].kaspad)
            return {
              lat: parseFloat(cords[0]),
              lon: parseFloat(cords[1]),
              version,
            } as NodeLocation
          }
          return undefined
        })
        .filter((i) => i !== undefined)
    }
    return []
  }, [data])

  const targetElement = useRef(null)
  const [isInView, setIsInView] = useState(false)
  const inViewTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  // Load map when it's in view for 1 second
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries
      if (entry.isIntersecting) {
        inViewTimeoutRef.current = setTimeout(() => {
          setIsInView(true)
        }, 300)
      }
    }, {})

    const currentTarget = targetElement.current
    if (currentTarget) {
      observer.observe(currentTarget)
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget)
      }
      if (inViewTimeoutRef.current) {
        clearTimeout(inViewTimeoutRef.current)
      }
    }
  }, [])

  return (
    <Card
      className="pb-8"
      title={
        <>
          {intl.formatMessage({
            id: "DASHBOARD.ACTIVE_NODES.TITLE",
          })}
          <Tooltip
            title={
              "Map shows public nodes with public IPs and exposed P2P ports only. Node locations are estimated by IP and may not be precise."
            }
          >
            <i className={`bi bi-exclamation-circle fs-3 mx-3`} />
          </Tooltip>
        </>
      }
      description={
        <div className="">
          {intl.formatMessage(
            {
              id: "DASHBOARD.ACTIVE_NODES.DESCRIPTION",
            },
            {
              activeNodes: (
                <>
                  <div className="blob d-inline-block mx-2"></div>
                  {locations.filter((i) => !isRustyNode(i?.version)).length}
                </>
              ),
              activeRustyNodes: (
                <>
                  <div className="blob-rusty d-inline-block mx-2"></div>
                  {locations.filter((i) => isRustyNode(i?.version)).length}
                </>
              ),
              updatedAt: formatUnixTime((data as NodesResponse)?.updated_at),
              crawlerGithub: (
                <a
                  className="text-primary"
                  href="https://github.com/tmrlvi/kaspa-crawler"
                  target="_blank"
                >
                  Github Source
                </a>
              ),
            }
          )}
        </div>
      }
      body={
        <div style={{ height: "50vh", width: "100%" }} ref={targetElement}>
          {isInView ? (
            <Map
              initialViewState={{
                longitude: -13.95931,
                latitude: 20.71807,
                zoom: 1,
              }}
              mapStyle={mapStyleUrl}
              maxZoom={0}
              mapboxAccessToken={MAPBOX_TOKEN}
              projection={{
                name: "mercator",
              }}
            >
              {locations.map((i, index) => {
                return (
                  i && (
                    <Marker key={index} latitude={i.lat} longitude={i.lon}>
                      <div
                        className={
                          isRustyNode(i.version) ? "blob-rusty" : "blob"
                        }
                      ></div>
                    </Marker>
                  )
                )
              })}
            </Map>
          ) : null}
        </div>
      }
    />
  )
}

export { NodeMapSection }
