/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl"
import { KSPR_AFFILIATE_URL } from "../../constants/constants"
import { trackTokenAdsClickedEvent } from "../../utils/tokenUtils"

export const MintOnKSPRBotButton = ({ ticker }: { ticker?: string }) => {
  const intl = useIntl()

  return (
    <a
      className="btn btn-success btn-sm"
      href={KSPR_AFFILIATE_URL}
      target="_blank"
      onClick={() => trackTokenAdsClickedEvent("kspr_mint_button")}
    >
      <i className="bi bi-robot fs-4 me-2"></i>
      <span className="fs-6 fw-semibold">
        {intl.formatMessage(
          { id: "MINT_ON_KSPR_BOT_FOR_TICKER" },
          { ticker: ticker || intl.formatMessage({ id: "KRC20_TOKENS" }) }
        )}
      </span>
    </a>
  )
}
