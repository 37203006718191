import { useRequest } from "ahooks"
import { useParams } from "react-router-dom"
import { Transaction, TransactionsService } from "../../../client"
import {
  BLOCK_POLLING_INTERVAL,
  rowClass,
  TITLE_SUFFIX,
} from "../../constants/constants"
import { HelmetWrapper } from "../../utils/HelmetWrappper"
import { TransactionBody } from "./TransactionBody"
import { TransactionHeader } from "./TransactionHeader"
import { AdsPlacement } from "../../components/Ads/AdsPlacement"
import { getRandomAdsPlatfrom } from "../../utils/utils"
import { useState } from "react"
import { TokenTransactionBody } from "./TokenTransactionBody"

const TransactionComponent = ({ hash }: { hash: string }) => {
  const [adsPlatform] = useState(() => getRandomAdsPlatfrom())
  const [transaction, setTransaction] = useState<Transaction>()
  // Because we only explicitly set indexed = false for transactions that are not indexed
  const indexed = transaction && transaction.indexed !== false ? true : false

  useRequest(
    async () => TransactionsService.transactionsControllerGetTransaction(hash),
    {
      pollingInterval: indexed ? undefined : BLOCK_POLLING_INTERVAL,
      onSuccess: (data) => {
        const indexedChanged =
          transaction !== undefined && transaction.indexed !== data.indexed
        if (indexedChanged) {
          window.location.reload()
        } else {
          setTransaction(data)
        }
      },
    }
  )

  return (
    <>
      <HelmetWrapper
        title={`Transaction ${hash} ${TITLE_SUFFIX}`}
        description={`Details of Kaspa Transaction ${hash}`}
      />
      <div className={rowClass}>
        <div className={"col-xl-4"}>
          <TransactionHeader
            tx={transaction}
            loading={!transaction}
            indexed={indexed}
          />
        </div>
        <div className={"col-xl-8"}>
          <TransactionBody
            transaction={transaction}
            loading={!transaction}
            indexed={indexed}
          />
          {transaction?.tokenTransactionData && (
            <TokenTransactionBody
              tokenTransaction={transaction.tokenTransactionData}
              loading={!transaction}
            />
          )}
          <AdsPlacement placementType="banner" platform={adsPlatform} />
        </div>
      </div>
    </>
  )
}

const TransactionWrapper = () => {
  const { hash = "" } = useParams()
  return <TransactionComponent key={hash} hash={hash} />
}

export { TransactionWrapper }
