import { GetTokenTradeVolumeResponse } from "../../../client"

export const TokenTradingVolume = ({
  tradeVolume,
}: {
  tradeVolume: GetTokenTradeVolumeResponse
}) => {
  return (
    <div className="d-flex align-items-center">
      $
      {tradeVolume.amountInUsd.toLocaleString(undefined, {
        maximumFractionDigits: 0,
      })}
    </div>
  )
}
