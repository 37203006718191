/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetAddressSummaryResponse } from '../models/GetAddressSummaryResponse';
import type { GetAddressTokensResponse } from '../models/GetAddressTokensResponse';
import type { GetAddressTransactionsResponse } from '../models/GetAddressTransactionsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AddressesService {

    /**
     * Return summary of a Kaspa address
     * @param address A Kaspa address
     * @returns GetAddressSummaryResponse
     * @throws ApiError
     */
    public static addressesControllerGetAddressSummary(
        address: string,
    ): CancelablePromise<GetAddressSummaryResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/addresses/{address}/info',
            path: {
                'address': address,
            },
        });
    }

    /**
     * Return list of transactions from a Kaspa address
     * @param nonce
     * @param address A Kaspa address
     * @param offset Number of transactions to skip
     * @param limit Number of transactions to return
     * @returns GetAddressTransactionsResponse
     * @throws ApiError
     */
    public static addressesControllerGetAddressTransactions(
        nonce: string,
        address: string,
        offset?: number,
        limit?: number,
    ): CancelablePromise<GetAddressTransactionsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/addresses/{address}/transactions',
            path: {
                'address': address,
            },
            query: {
                'nonce': nonce,
                'offset': offset,
                'limit': limit,
            },
        });
    }

    /**
     * Return token list of a Kaspa address
     * @param address A Kaspa address
     * @returns GetAddressTokensResponse
     * @throws ApiError
     */
    public static addressesControllerGetAddressTokens(
        address: string,
    ): CancelablePromise<Array<GetAddressTokensResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/addresses/{address}/tokens',
            path: {
                'address': address,
            },
        });
    }

}
