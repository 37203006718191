/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from "react"
import { Tree } from "antd"
import type { DataNode, TreeProps } from "antd/es/tree"
import { useIntl } from "react-intl"

interface DraggableSelectionListProps {
  nodes: DataNode[]
  initialCheckedKeys?: string[]
  onConfirm: (keys: string[]) => void
  onClickReset: () => void
}

const DraggableSelectionList: React.FC<DraggableSelectionListProps> = ({
  nodes,
  initialCheckedKeys = [],
  onConfirm,
  onClickReset,
}) => {
  const intl = useIntl()
  const [gData, setGData] = useState(nodes)
  const [checkedKeys, setCheckedKeys] = useState<string[]>(initialCheckedKeys)

  const onCheck: TreeProps["onCheck"] = (checkedKeys, info) => {
    setCheckedKeys(checkedKeys as string[])
  }

  const onDrop: TreeProps["onDrop"] = (info) => {
    const dropKey = info.node.key
    const dragKey = info.dragNode.key
    const dropPos = info.node.pos.split("-")
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

    const loop = (
      data: DataNode[],
      key: React.Key,
      callback: (node: DataNode, i: number, data: DataNode[]) => void
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data)
        }
        if (data[i].children) {
          loop(data[i].children!, key, callback)
        }
      }
    }
    const data = [...gData]

    // Find dragObject
    let dragObj: DataNode
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1)
      dragObj = item
    })

    let ar: DataNode[] = []
    let i: number
    loop(data, dropKey, (_item, index, arr) => {
      ar = arr
      i = index
    })
    if (dropPosition === -1) {
      ar.splice(i!, 0, dragObj!)
    } else {
      ar.splice(i! + 1, 0, dragObj!)
    }

    setGData(data)
  }

  const onConfirmSelection = () => {
    // sort the keys, since gData is sorted
    const sortedKeys = gData
      .filter((node) => checkedKeys.includes(node.key.toString()))
      .map((node) => node.key.toString())
    onConfirm(sortedKeys)
  }

  return (
    <div className="w-100 d-flex align-items-center row">
      <Tree
        checkable
        onCheck={onCheck}
        treeData={gData}
        draggable
        onDrop={onDrop}
        checkedKeys={checkedKeys}
        selectable={false}
        className="bg-body"
        style={{ paddingTop: "12px", paddingBottom: "12px" }}
      />

      <a className="btn btn-primary mt-10" onClick={onConfirmSelection}>
        {intl.formatMessage({ id: "SAVE" })}
      </a>

      <a className="btn text-muted" onClick={onClickReset}>
        {intl.formatMessage({ id: "RESET" })}
      </a>
    </div>
  )
}

export default DraggableSelectionList
