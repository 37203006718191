import { Helmet } from "react-helmet"
import { DEFAULT_DESCRIPTION, DEFAULT_KEYWORDS } from "../constants/constants"

interface HelmetProps {
  title: string
  description?: string
  keywords?: string[]
}

export const HelmetWrapper = ({
  title,
  description = DEFAULT_DESCRIPTION,
  keywords = [],
}: HelmetProps) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content={[...keywords, ...DEFAULT_KEYWORDS].join(", ")}
      />
    </Helmet>
  )
}
