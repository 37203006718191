import { useIntl } from "react-intl"
import { CONFIRMED_COUNT } from "../constants/constants"
import { Spin, Tooltip } from "antd"
import { LoadingOutlined } from "@ant-design/icons"

const STATUS_INTL_PREFIX = "TRANSACTION.STATUS"

const LoadingSpin = () => (
  <Spin
    className="me-2"
    indicator={<LoadingOutlined style={{ fontSize: 8, color: "#fff" }} spin />}
  />
)

export const TransactionStatusTag = ({
  accepted,
  confirmations,
  unknown = false,
  indexed = true,
  className = "mb-2",
}: {
  accepted?: boolean
  confirmations?: number
  unknown?: boolean
  indexed?: boolean
  className?: string
}) => {
  const intl = useIntl()

  if (unknown) {
    return (
      <span className={`badge badge-light-secondary ${className}`}>
        <Tooltip
          title={intl.formatMessage({
            id: `${STATUS_INTL_PREFIX}.UNKNOWN.TOOLTIP`,
          })}
        >
          {intl.formatMessage({ id: `${STATUS_INTL_PREFIX}.UNKNOWN` })}
        </Tooltip>
      </span>
    )
  } else if (!indexed) {
    return (
      <>
        <span className={`badge badge-info ${className}`}>
          <Tooltip
            title={intl.formatMessage({
              id: `${STATUS_INTL_PREFIX}.INDEXING.TOOLTIP`,
            })}
          >
            <LoadingSpin />
            {intl.formatMessage({ id: `${STATUS_INTL_PREFIX}.INDEXING` })}
          </Tooltip>
        </span>
      </>
    )
  }

  if (accepted === undefined) return null

  const confirmationsMissing =
    confirmations === null || confirmations === undefined
  const isConfirmed = confirmations && confirmations >= CONFIRMED_COUNT

  const statusText = !accepted
    ? "NOT_ACCEPTED"
    : isConfirmed
    ? "CONFIRMED"
    : "ACCEPTED"

  // Do not show confirms # if confirmations missing, otherwise show it only if not fully confirmed
  let confirmationsText
  if (confirmationsMissing || isConfirmed) {
    confirmationsText = ""
  } else if (!isConfirmed) {
    confirmationsText = confirmations > 0 ? ` (${confirmations})` : ""
  }

  return accepted ? (
    <span className={`badge badge-light-success ${className}`}>
      {intl.formatMessage({ id: `${STATUS_INTL_PREFIX}.${statusText}` })}
      {confirmationsText}
    </span>
  ) : (
    <span className={`badge badge-light-danger ${className}`}>
      {intl.formatMessage({ id: `${STATUS_INTL_PREFIX}.${statusText}` })}
    </span>
  )
}
