/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from "react"
import { LoadingOverlay } from "./LoadingOverlay"
import { useIntl } from "react-intl"
import { Area, AreaConfig } from "@ant-design/charts"
import { useAntVTheme } from "../../../hooks/useAntVTheme"
import { GraphDataPoint } from "../../../../client"
import moment from "moment"

interface ChartDataProps {
  data?: GraphDataPoint[]
  seriesName: string
}

interface Props {
  className: string
  chartName: string
  chartDescription?: string
  chartData: ChartDataProps
  loading?: boolean
}

const SimpleChart: React.FC<Props> = ({
  className,
  chartData,
  loading,
  chartName,
  chartDescription,
}) => {
  const intl = useIntl()
  const theme = useAntVTheme()

  const [segment, setSegment] = useState(7)
  const trimmedData = useMemo(() => {
    return chartData.data ? chartData.data.slice(-segment) : []
  }, [chartData.data, segment])

  const Toolbar = () => {
    return (
      <div className="card-toolbar" data-kt-buttons="true">
        <a
          className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${
            segment === 90 && "active"
          } px-4 me-1`}
          id="kt_charts_widget_3_year_btn"
          onClick={() => setSegment(90)}
        >
          {intl.formatMessage({ id: "CHART.SEGMENT.90_DAYS" })}
        </a>

        <a
          className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${
            segment === 30 && "active"
          } px-4 me-1`}
          id="kt_charts_widget_3_month_btn"
          onClick={() => setSegment(30)}
        >
          {intl.formatMessage({ id: "CHART.SEGMENT.30_DAYS" })}
        </a>

        <a
          className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${
            segment === 7 && "active"
          } px-4 me-1`}
          id="kt_charts_widget_3_week_btn"
          onClick={() => setSegment(7)}
        >
          {intl.formatMessage({ id: "CHART.SEGMENT.7_DAYS" })}
        </a>
      </div>
    )
  }

  const toUtcDateText = (date: string, format = "MM/DD") => {
    return moment.utc(date).format(format)
  }

  const chartConfig: AreaConfig = {
    data: trimmedData,
    theme,
    height: 350,
    xField: "x",
    yField: "y",
    smooth: true,
    xAxis: {
      label: {
        formatter: (value) => {
          return toUtcDateText(value)
        },
      },
    },
    yAxis: {
      label: {
        formatter: (value) => parseInt(value).toLocaleString(),
      },
      min: Math.min(...trimmedData.map((i) => i.y)) * 0.95,
    },
    tooltip: {
      formatter: (datum) => {
        let extraText = ""
        if (toUtcDateText(datum.x, "YYYY/MM/DD") === "2023/09/24") {
          extraText += " (Dust attack)"
        }

        return {
          name: `${chartData.seriesName}${extraText}`,
          value: parseInt(datum.y).toLocaleString(),
        }
      },
      title: (value) => {
        return toUtcDateText(value) + " (UTC)"
      },
    },
  }

  return (
    <div className={`card ${className} ${loading && "overlay overlay-block"}`}>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">{chartName}</span>
          <span className="text-muted fw-semibold fs-7">
            {chartDescription}
          </span>
        </h3>
        {segment !== undefined && <Toolbar />}
      </div>

      <div className="card-body">
        {chartConfig && <Area {...chartConfig} />}
      </div>

      {loading && <LoadingOverlay />}
    </div>
  )
}

export { SimpleChart }
