/* eslint-disable react/jsx-no-target-blank */
import { Tooltip } from "antd"
import { AddressTag as AddressTagModel } from "../../../client"
import { useIntl } from "react-intl"

interface TagProps {
  className: string
  tag: AddressTagModel
  disableLink?: boolean
}

export const RegularTag = ({ className, tag, disableLink }: TagProps) => {
  const linkIcon = tag.link && !disableLink && (
    <i className="fa-solid fa-arrow-up-right-from-square ms-2 fs-7 text-primary"></i>
  )
  const TagElement = disableLink ? "span" : "a"
  return (
    <TagElement
      className={`badge mh-20px badge-light-primary ${className}`}
      {...(!disableLink && { href: tag.link, target: "_blank" })}
    >
      {tag?.name}
      {linkIcon}
    </TagElement>
  )
}

export const RankTag = ({ className, tag, disableLink }: TagProps) => {
  const intl = useIntl()

  return (
    <Tooltip title={intl.formatMessage({ id: "ADDRESS.DETAIL.RANK.TOOLTIP" })}>
      {tag.link && !disableLink ? (
        <a
          className={`badge mh-20px badge-light ${className}`}
          href={tag.link}
          target="_blank"
        >
          {tag?.name}
        </a>
      ) : (
        <span className={`badge mh-20px badge-light ${className}`}>
          {tag?.name}
        </span>
      )}
    </Tooltip>
  )
}

export const HeistTag = ({ className, tag, disableLink }: TagProps) => {
  const linkIcon = tag.link && !disableLink && (
    <i className="fa-solid fa-arrow-up-right-from-square ms-2 fs-7 text-primary"></i>
  )
  const TagElement = disableLink ? "span" : "a"
  return (
    <div className="d-flex align-items-center">
      <TagElement
        className={`badge mh-20px badge-light-danger ${className}`}
        {...(!disableLink && { href: tag.link, target: "_blank" })}
      >
        {tag?.name}
        {linkIcon}
      </TagElement>
      <TagElement
        className={`badge mh-20px badge-light-danger`}
        {...(!disableLink && { href: tag.link, target: "_blank" })}
      >
        {"Heist"}
      </TagElement>
    </div>
  )
}

export const AddressTag = ({
  className,
  tag,
  disableLink = false,
}: {
  className: string
  tag: AddressTagModel
  disableLink?: boolean
}) => {
  switch (tag.type) {
    case "heist":
      return (
        <HeistTag className={className} tag={tag} disableLink={disableLink} />
      )
    case "rank":
      return (
        <RankTag className={className} tag={tag} disableLink={disableLink} />
      )
    default:
      return (
        <RegularTag className={className} tag={tag} disableLink={disableLink} />
      )
  }
}
