/* eslint-disable jsx-a11y/anchor-is-valid */

interface SimplePaginationProps {
  currentPage: number
  totalPage: number
  onPreviousPage: () => void
  onNextPage: () => void
  addressTnxLoading: boolean
  transactionsCardRef?: React.RefObject<HTMLDivElement>
  hideTotalPage?: boolean
}

export const SimplePagination = ({
  currentPage,
  totalPage,
  onPreviousPage,
  onNextPage,
  addressTnxLoading,
  transactionsCardRef,
  hideTotalPage,
}: SimplePaginationProps) => {
  return (
    <ul className="pagination">
      <li
        className={`page-item previous ${
          (currentPage <= 1 || addressTnxLoading) && "disabled"
        }`}
      >
        <a
          className={`page-link btn`}
          onClick={() => {
            transactionsCardRef?.current?.scrollIntoView()
            onPreviousPage()
          }}
        >
          <i className="previous"></i>
        </a>
      </li>
      <li className="page-item fs-6 d-flex align-items-center mx-2 ">
        {hideTotalPage ? currentPage : `${currentPage} / ${totalPage}`}
      </li>
      <li
        className={`page-item next ${
          (currentPage === totalPage || addressTnxLoading) && "disabled"
        }`}
      >
        <a
          className="page-link btn"
          onClick={() => {
            transactionsCardRef?.current?.scrollIntoView()
            onNextPage()
          }}
        >
          <i className="next"></i>
        </a>
      </li>
    </ul>
  )
}
