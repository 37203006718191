/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  getAddressRoute,
  getBlockRoute,
  getTokenRoute,
  getTransactionRoute,
} from "../hooks/navigator"
import { useIntl } from "react-intl"
import { AdsAlertBody } from "./partials/AdsAlert"
import { Divider } from "antd"
import { TokenIcon } from "./partials/TokenIcon"
import { Link } from "react-router-dom"
import { SearchNetworkResult } from "../../client"
import { AddressTag } from "./partials/AddressTag"
import { SEARCH_BAR_MIN_QUERY_LENGTH } from "../constants/constants"

export enum SearchResultType {
  ADDRESS = "address",
  TRANSACTION = "transaction",
  BLOCK = "block",
  TOKEN = "token",
}

const AntDivider = () => <Divider style={{ margin: "12px 0" }} />

const TopRow = () => {
  return <div className="fs-6"></div>
}

const NoResultsRow = ({
  notEnoughCharacters,
}: {
  notEnoughCharacters?: boolean
}) => {
  const intl = useIntl()
  return (
    <span>
      <AntDivider />
      <div className="d-flex align-items-center fw-semibold mt-4">
        <i className="bi bi-question-circle-fill me-2 fs-5"></i>
        {notEnoughCharacters ? (
          <span>
            {intl.formatMessage({ id: "SEARCH_BAR.NOT_ENOUGH_CHARACTERS" })}
          </span>
        ) : (
          <span>{intl.formatMessage({ id: "SEARCH_BAR.NO_RESULTS" })}</span>
        )}
      </div>
    </span>
  )
}

const SearchResultRow = ({
  result,
  onClose,
}: {
  result: SearchNetworkResult
  onClose: () => void
}) => {
  const intl = useIntl()
  const { type, value, tag } = result
  const resultType = type as SearchResultType

  const typeToSubtitle = (type: SearchResultType) => {
    switch (type) {
      case SearchResultType.ADDRESS:
        return intl.formatMessage({ id: "ADDRESS" })
      case SearchResultType.TRANSACTION:
        return intl.formatMessage({ id: "TRANSACTION" })
      case SearchResultType.BLOCK:
        return intl.formatMessage({ id: "BLOCK" })
      case SearchResultType.TOKEN:
        return intl.formatMessage({ id: "TOKEN" })
      default:
        return ""
    }
  }

  const typeToRoute = (result: SearchNetworkResult) => {
    const { value } = result
    switch (type) {
      case SearchResultType.ADDRESS:
        return getAddressRoute(value)
      case SearchResultType.TRANSACTION:
        return getTransactionRoute(value)
      case SearchResultType.BLOCK:
        return getBlockRoute(value)
      case SearchResultType.TOKEN:
        return getTokenRoute(value)
      default:
        return "#"
    }
  }

  const typeToIcon = (result: SearchNetworkResult) => {
    const { type, iconUrl } = result
    const className = "me-2 text-muted fs-2x fs-md-1"
    switch (type) {
      case SearchResultType.ADDRESS:
        return <i className={`las la-wallet ${className}`}></i>
      case SearchResultType.TRANSACTION:
        return <i className={`las la-exchange-alt ${className}`}></i>
      case SearchResultType.BLOCK:
        return <i className={`las la-cube ${className}`}></i>
      case SearchResultType.TOKEN:
        return <TokenIcon url={iconUrl} size="20px" className="me-3" />
    }
  }

  return (
    <span>
      <AntDivider />
      <Link
        to={typeToRoute(result)}
        onClick={onClose}
        className="d-flex text-dark text-hover-primary align-items-center"
      >
        {typeToIcon(result)}
        <div className="d-flex flex-column justify-content-start fw-semibold text-truncate">
          <span className="d-flex align-items-center gap-1">
            <span className="fs-6 fw-bold text-truncate">{value}</span>
            {tag ? (
              <AddressTag className="" tag={tag} disableLink={true} />
            ) : null}
            <span className="fw-bold badge mh-20px badge-light">
              {typeToSubtitle(resultType)}
            </span>
          </span>
        </div>
      </Link>
    </span>
  )
}

export const SearchBarResults = ({
  width,
  query,
  results,
  onClose,
}: {
  width: number
  query?: string
  results?: SearchNetworkResult[]
  onClose: () => void
}) => {
  return (
    <div
      className="scroll-y mh-350px p-5 search-bar-results-dropdown"
      style={{
        width: width + 16,
        maxHeight: "300px",
        minHeight: "50px",
      }}
    >
      <TopRow />

      {!results &&
      query &&
      query.length > 0 &&
      query.length < SEARCH_BAR_MIN_QUERY_LENGTH ? (
        <NoResultsRow notEnoughCharacters={true} />
      ) : null}

      {results ? (
        results.length === 0 ? (
          <NoResultsRow />
        ) : (
          results.map((result) => (
            <SearchResultRow
              key={result.value}
              result={result}
              onClose={onClose}
            />
          ))
        )
      ) : null}
    </div>
  )
}
