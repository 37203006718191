/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { QRCode } from "antd"
import { FC } from "react"
import { useIntl } from "react-intl"
import { PasteIcon } from "../../../app/components/PasteIcon"
import {
  DONATION_ADDRESS,
  TELEGRAM_SUPPORT_URL,
} from "../../../app/constants/constants"
import { KTSVG } from "../../helpers"
import { useLayout } from "../core"

const Footer: FC = () => {
  const intl = useIntl()

  const donationModal = (
    <div className="modal fade" tabIndex={-1} id="donation-address-modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {intl.formatMessage({ id: "DONATION_LINK.NAME" })}
            </h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          <div className="modal-body d-flex flex-column align-items-center">
            <QRCode bordered={false} value={DONATION_ADDRESS} />
            <p className="fs-6 text-dark text-break text-center mt-4">
              {DONATION_ADDRESS}
              <PasteIcon value={DONATION_ADDRESS} showExplosion />
            </p>
            <div className="alert alert-primary d-flex align-items-center mt-2">
              <div className="d-flex flex-column">
                <h5 className="mb-1">
                  {intl.formatMessage({ id: "DONATION.MODAL.TOOLTIP.TITLE" })}
                </h5>
                <span className="text-dark">
                  {intl.formatMessage(
                    { id: "DONATION.MODAL.TOOLTIP.MESSAGE" },
                    {
                      br: <br />,
                      contactLink: (
                        <a
                          className="text-primary"
                          href={TELEGRAM_SUPPORT_URL}
                          target="_blank"
                        >
                          {intl.formatMessage({
                            id: "DONATION.MODAL.TOOLTIP.MESSAGE.CONTACT_LINK",
                          })}
                        </a>
                      ),
                      donorListLink: (
                        <a className="text-primary" href="/donors">
                          {intl.formatMessage({
                            id: "DONATION.MODAL.TOOLTIP.MESSAGE.DONOR_LINK",
                          })}
                        </a>
                      ),
                    }
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  const { classes } = useLayout()
  return (
    <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
      {donationModal}

      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-semibold me-2">
            {new Date().getFullYear()} &copy;
          </span>
          <a href="/" className="text-gray-800 text-hover-primary">
            Kas.fyi
          </a>
        </div>

        <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
          <li className="menu-item mx-4" role="button">
            <a
              className="menu-link ps-0 pe-2"
              target="_blank"
              href="https://humpool.com/"
            >
              {intl.formatMessage({ id: "ADS_PLACEMENT.HUMPOOL.FOOTER" })}
            </a>
          </li>
          <li className="menu-item mx-4" role="button">
            <a className="menu-link ps-0 pe-2" href="/donors">
              {intl.formatMessage({ id: "DONOR_LIST.NAME" })}
            </a>
          </li>

          <li
            className="menu-item mx-4"
            data-bs-toggle="modal"
            data-bs-target="#donation-address-modal"
            role="button"
          >
            <a className="menu-link ps-0 pe-2">
              {intl.formatMessage({ id: "DONATION_LINK.NAME" })}
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export { Footer }
