import { useRequest } from "ahooks"
import { useParams } from "react-router-dom"
import { Block, BlocksService, Transaction } from "../../../client"
import {
  BLOCK_POLLING_INTERVAL,
  rowClass,
  TITLE_SUFFIX,
} from "../../constants/constants"
import { HelmetWrapper } from "../../utils/HelmetWrappper"
import { BlockHeader } from "./BlockHeader"
import { BlockTransactions } from "./BlockTransactions"
import { AdsPlacement } from "../../components/Ads/AdsPlacement"
import { getRandomAdsPlatfrom } from "../../utils/utils"
import { useState } from "react"

const BlockComponent = ({ hash }: { hash: string }) => {
  const [adsPlatform] = useState(() => getRandomAdsPlatfrom())
  const [blockInfo, setBlockInfo] = useState<Block>()
  const [blockTransactions, setBlockTransactions] = useState<Transaction[]>()
  // Because we only explicitly set indexed = false for blocks that are not indexed
  const indexed = blockInfo && blockInfo.indexed !== false ? true : false

  useRequest(async () => BlocksService.blocksControllerGetBlockInfo(hash), {
    pollingInterval: indexed ? undefined : BLOCK_POLLING_INTERVAL,
    onSuccess: (data) => {
      const indexedChanged =
        blockInfo !== undefined && blockInfo.indexed !== data.indexed
      if (indexedChanged) {
        window.location.reload()
      } else {
        setBlockInfo(data)
      }
    },
  })

  useRequest(
    async () => BlocksService.blocksControllerGetBlockTransaction(hash),
    {
      onSuccess: (data) => {
        setBlockTransactions(data)
      },
    }
  )

  return (
    <div className={rowClass}>
      <HelmetWrapper
        title={`Block ${hash} ${TITLE_SUFFIX}`}
        description={`Details of Kaspa block ${hash} with ${blockTransactions?.length} transactions`}
      />
      <div className={"col-xl-4"}>
        <BlockHeader block={blockInfo} loading={!blockInfo} />
      </div>
      <div className={"col-xl-8"}>
        <BlockTransactions
          transactions={blockTransactions}
          loading={!blockTransactions}
          indexed={indexed}
        />
        <AdsPlacement placementType="banner" platform={adsPlatform} />
      </div>
    </div>
  )
}

const BlockWrapper = () => {
  const { hash = "" } = useParams()
  return <BlockComponent key={hash} hash={hash} />
}

export { BlockWrapper }
