/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react"
import ApexCharts, { ApexOptions } from "apexcharts"
import { getCSSVariableValue } from "../../../../_metronic/assets/ts/_utils"
import { useThemeMode } from "../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider"
import { MarketDataResponse } from "../../../../client"
import { LoadingOverlay } from "./LoadingOverlay"
import { useIntl } from "react-intl"
import { UNIT_STRING } from "../../../constants/constants"

type Props = {
  className: string
  chartHeight: string
  chartColor: string
  marketData?: MarketDataResponse
}

const percentageFormatter = (val: number) => {
  let badgeColor, sign
  if (val >= 0.1) {
    badgeColor = "badge-light-success"
    sign = "+"
  } else if (val <= -0.1) {
    badgeColor = "badge-light-danger"
    sign = ""
  } else {
    badgeColor = "badge-light"
    sign = ""
  }
  const text = `${sign}${val.toFixed(1)}%`
  return <span className={`badge ${badgeColor} fs-base`}>{text}</span>
}

const MarketDataCard: React.FC<Props> = ({
  className,
  chartHeight,
  chartColor,
  marketData,
}) => {
  const intl = useIntl()
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartHeight, chartColor)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode])

  const price = marketData?.price?.toFixed(4)
  const priceDelta = marketData?.priceChange24h || 0
  const volume24H = marketData?.volume24h?.toLocaleString(undefined, {
    maximumFractionDigits: 0,
  })
  const marketCap = marketData?.marketCap?.toLocaleString(undefined, {
    maximumFractionDigits: 0,
  })
  const marketCapRanking = marketData?.rank

  return (
    <div
      className={`card ${className} ${!marketCap && "overlay overlay-block"}`}
    >
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            {intl.formatMessage({ id: "DASHBOARD.MARKET.TITLE" })}
          </span>
        </h3>
      </div>

      <div className="card-body p-0 d-flex flex-column">
        <div className="card-p pt-5 flex-grow-1">
          <div className="row g-0">
            <div className="col-7">
              <div className="fs-7 text-muted fw-semibold">
                {intl.formatMessage({ id: "DASHBOARD.MARKET.PRICE.TITLE" })}
              </div>

              <div className="d-flex">
                <span className="fs-4 fw-bold">
                  ${price}/{UNIT_STRING}
                </span>
                <span className="ms-2">{percentageFormatter(priceDelta)}</span>
              </div>
            </div>

            <div className="col-5">
              <div className="fs-7 text-muted fw-semibold">
                {intl.formatMessage({ id: "DASHBOARD.MARKET.VOLUME.TITLE" })}
              </div>

              <div className="fs-4 fw-bold">${volume24H}</div>
            </div>
          </div>

          <div className="row g-0 mt-8">
            <div className="col-7">
              <div className="fs-7 text-muted fw-semibold">
                {intl.formatMessage({
                  id: "DASHBOARD.MARKET.MARKET_CAP.TITLE",
                })}
              </div>

              <div className="fs-4 fw-bold">${marketCap}</div>
            </div>

            <div className="col-5">
              <div className="fs-7 text-muted fw-semibold">
                {intl.formatMessage({
                  id: "DASHBOARD.MARKET.MC_RANKING.TITLE",
                })}
              </div>

              <div className="d-flex align-items-center">
                <div className="fs-4 fw-bold">#{marketCapRanking}</div>
              </div>
            </div>
          </div>
        </div>
        {/* end::Stats */}

        {/* begin::Chart */}
        <div
          ref={chartRef}
          className="mixed-widget-3-chart card-rounded-bottom"
        ></div>
        {/* end::Chart */}
      </div>

      {!marketData && <LoadingOverlay />}
      {/* end::Body */}
    </div>
  )
}

const chartOptions = (chartHeight: string, chartColor: string): ApexOptions => {
  const labelColor = getCSSVariableValue("--kt-gray-800")
  const strokeColor = getCSSVariableValue("--kt-gray-300")
  const baseColor = getCSSVariableValue("--kt-" + chartColor)
  const lightColor = getCSSVariableValue("--kt-" + chartColor + "-light")

  return {
    series: [
      {
        name: "Net Profit",
        data: [30, 25, 45, 30, 55, 55],
      },
    ],
    chart: {
      fontFamily: "inherit",
      type: "area",
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
      opacity: 1,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      min: 0,
      max: 60,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  }
}

export { MarketDataCard }
