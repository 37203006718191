/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"
import { useIntl } from "react-intl"
import { GetTokenSummaryResponse } from "../../../../client"
import { Button, Drawer, Popover, Skeleton, Space as AntdSpace } from "antd"
import { formatUnixTime, getTimeDifference } from "../../../utils/utils"
import { MintOnKSPRBotButton } from "../../../components/partials/MintOnKSPRBotButton"
import { TradeOnKSPRBotButton } from "../../../components/partials/TradeOnKSPRBotButton"
import { UNIT_STRING } from "../../../constants/constants"
import { FiatAmount } from "../../../components/FiatAmountBadge"
import { TokenIcon } from "../../../components/partials/TokenIcon"
import { PriceDiffBadge } from "../../../components/PriceDiffBadge"
import { PremintPctBadge } from "../../../components/partials/PremintPctBadge"
import { TokenMintedProgressiveBar } from "../../../components/partials/TokenMintedProgressiveBar"
import { getTokenStatus } from "../../../utils/tokenUtils"
import { TokenTradingVolume } from "../../../components/partials/TokenTradingVolume"
import { DownOutlined } from "@ant-design/icons"
import { TokenSocials } from "./TokenSocials"
import { TradeOnChaingeButton } from "../../../components/partials/TradeOnChaingeButton"
import { getAddressRoute, getTransactionRoute } from "../../../hooks/navigator"
import { Link } from "react-router-dom"
import { useGlobalData } from "../../../utils/GlobalDataProvider"
import { Krc20PriceUnitToggleButton } from "../../../components/partials/Krc20PriceUnitToggleButton"
import TokenDetailsEditForm from "./TokenDetailsEditForm"
import { LowLiquidityWarningIcon } from "../../../components/partials/LowLiquidityWarningIcon"
import { AdsPlacement } from "../../../components/Ads/AdsPlacement"

const Space = () => <div className="my-5"></div>

const DashSeparator = () => (
  <div className="separator separator-dashed my-6"></div>
)

const labelClass = "fw-semibold text-muted text-break fs-6"
const valueClass =
  "fw-semibold text-dark text-break fs-6 d-flex align-items-center"

const renderCell = (label: string, value: React.ReactNode) => {
  return (
    <div className="d-flex flex-wrap">
      <div>
        <div className={`d-flex align-items-center mb-1`}>
          <span className={labelClass}>{label}</span>
        </div>
        {value}
      </div>
    </div>
  )
}

interface TokenHeaderProps {
  token?: GetTokenSummaryResponse
  loading?: boolean
}

const TokenHeader: React.FC<TokenHeaderProps> = ({ token, loading }) => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  const { marketData, krc20PriceUnit } = useGlobalData()

  const showDrawer = () => {
    if (token?.ticker) {
      setOpen(true)
    }
  }

  const onClose = () => {
    setOpen(false)
  }

  const price = token?.price?.floorPrice || 0
  const ticker = token?.ticker || ""

  const displayPrice =
    krc20PriceUnit === "KAS"
      ? `${price.toFixed(8)} ${UNIT_STRING}`
      : `$${(price * (marketData?.price || 0)).toLocaleString(undefined, {
          maximumFractionDigits: 8,
        })}`

  const change24h = token?.price?.change24h || 0
  const marketCapInKas =
    (price || 0) * ((token?.totalMinted || 0) / 10 ** (token?.decimal || 1))
  const tradeVolume = token?.tradeVolume

  const headerLoading = loading && !token

  return (
    <div className={`card mb-6`}>
      <Drawer
        title={intl.formatMessage({ id: "TOKEN.HEADER.EDIT_TOKEN_DETAILS" })}
        onClose={onClose}
        open={open}
        size="large"
        destroyOnClose
        className="bg-body"
      >
        <TokenDetailsEditForm token={token} />
      </Drawer>
      <div className="card-header border-0">
        <div className="card-title m-0">
          <h3 className="fw-bold m-0 d-flex align-items-center">
            <TokenIcon url={token?.iconUrl} className="me-3" size="25px" />
            <span className="me-2">
              {intl.formatMessage(
                { id: "TOKEN.HEADER.TOKEN_DETAILS" },
                { ticker }
              )}
            </span>
            <i
              role="button"
              className="fs-5 bi bi-pencil-square text-muted text-hover-primary"
              onClick={showDrawer}
            ></i>
          </h3>
        </div>
      </div>

      <div className="card-body border-top p-9">
        {headerLoading ? (
          <Skeleton active />
        ) : (
          <>
            {renderCell(
              intl.formatMessage({ id: "FLOOR_PRICE" }),
              <div className={valueClass}>
                <div className="d-flex align-items-center">
                  <span className="me-2">{displayPrice}</span>
                  {change24h ? (
                    <PriceDiffBadge diff={change24h} precision={1} />
                  ) : null}
                </div>

                <span className="ms-2">
                  <Krc20PriceUnitToggleButton />
                </span>
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "DASHBOARD.MARKET.MARKET_CAP.TITLE" }),
              <div className={valueClass}>
                {marketCapInKas > 0 ? (
                  <>
                    {FiatAmount({ kaspaAmount: marketCapInKas, precision: 0 })}
                    {tradeVolume?.amountInUsd === 0 && (
                      <LowLiquidityWarningIcon />
                    )}
                  </>
                ) : (
                  "-"
                )}
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TRADING_VOLUME_24H" }),
              <div className={valueClass}>
                {tradeVolume && tradeVolume.amountInUsd > 0 ? (
                  <TokenTradingVolume tradeVolume={tradeVolume} />
                ) : (
                  "-"
                )}
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.TOTAL_MINTED" }),
              <div className={valueClass}>
                {token && (
                  <div className="w-150px">
                    <TokenMintedProgressiveBar token={token} fullTotalSupply />
                  </div>
                )}
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.PRE_MINT" }),
              <div className={valueClass}>
                {token && <PremintPctBadge token={token} />}
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.STATUS" }),
              <div className={valueClass}>
                <span className="badge badge-light-success">
                  {getTokenStatus(intl, token?.status || "")}
                </span>
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.DEPLOYED_BY" }),
              <div className={valueClass}>
                <Link
                  to={getAddressRoute(token?.deployerAddress || "#")}
                  className="text-body text-hover-primary"
                >
                  {token?.deployerAddress}
                </Link>
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.DEPLOYED_AT" }),
              <div className={valueClass}>
                <Link
                  to={getTransactionRoute(token?.revealHash || "#")}
                  className="text-body text-hover-primary"
                >
                  {formatUnixTime(
                    token?.deployedAt ? token?.deployedAt / 1e3 : 0
                  )}
                </Link>

                <span className="badge badge-light ms-2">
                  {getTimeDifference(token?.deployedAt || 0, false)}
                </span>
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.HOLDER_COUNT" }),
              <div className={valueClass}>
                {token?.holderTotal.toLocaleString()}
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.MINT_COUNT" }),
              <div className={valueClass}>
                {token?.mintTotal.toLocaleString()}
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.TRANSFER_COUNT" }),
              <div className={valueClass}>
                {token?.transferTotal.toLocaleString()}
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.ACTIONS" }),
              <div className="d-flex flex-column">
                {token?.status !== "finished" && (
                  <div className="mb-4">
                    <MintOnKSPRBotButton ticker={ticker} />
                  </div>
                )}
                <span className="mb-4">
                  <TradeOnKSPRBotButton ticker={ticker} />
                </span>
                <TradeOnChaingeButton ticker={ticker} />
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.SOCIAL_LINKS" }),
              <Popover
                overlayInnerStyle={{ padding: 1 }}
                autoAdjustOverflow={false}
                content={<TokenSocials socialLinks={token?.socialLinks} />}
                trigger="click"
                placement="bottomLeft"
                arrow={false}
              >
                <Button
                  loading={headerLoading}
                  disabled={!token?.socialLinks?.length}
                >
                  <AntdSpace>
                    {token?.socialLinks?.length
                      ? intl.formatMessage({
                          id: "TOKEN.HEADER.SOCIAL_LINKS.CLICK_TO_VIEW",
                        })
                      : intl.formatMessage({ id: "NOT_AVAILABLE" })}
                    <DownOutlined />
                  </AntdSpace>
                </Button>
              </Popover>
            )}

            <DashSeparator />

            {renderCell(
              intl.formatMessage({ id: "SPONSORED" }),
              <div className="mt-3">
                <AdsPlacement placementType="box" platform={"ads-server"} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export { TokenHeader }
