import moment from "moment"
import { TransactionOutput } from "../../client"
import { KaspadOutputType } from "./types"
import { PlatformType } from "../components/Ads/AdsPlacement"
import { UNIT_STRING } from "../constants/constants"

export const formatHashrate = (
  rate?: number
): { value: number | undefined; unit?: string } => {
  if (!rate) return { value: undefined }

  let unit = "H/s"
  if (rate >= 1000) {
    rate /= 1000
    unit = "KH/s"
  }
  if (rate >= 1000) {
    rate /= 1000
    unit = "MH/s"
  }
  if (rate >= 1000) {
    rate /= 1000
    unit = "GH/s"
  }
  if (rate >= 1000) {
    rate /= 1000
    unit = "TH/s"
  }
  if (rate >= 1000) {
    rate /= 1000
    unit = "PH/s"
  }
  if (rate >= 1000) {
    rate /= 1000
    unit = "EH/s"
  }
  return { value: rate, unit }
}

export const formatSupplyNumber = (n?: number) => {
  if (n === undefined) return { value: undefined, unit: undefined }
  if (n < 1e3) return { value: n, unit: "" }
  if (n >= 1e3 && n < 1e6) return { value: +(n / 1e3), unit: "K" }
  if (n >= 1e6 && n < 1e9) return { value: +(n / 1e6), unit: "M" }
  if (n >= 1e9 && n < 1e12) return { value: +(n / 1e9), unit: "B" }
  if (n >= 1e12) return { value: +(n / 1e12), unit: "T" }
}

export const shortenHash = (hash?: string) => {
  return `${hash?.substring(0, 4)}-${hash?.substring(hash?.length - 4)}`
}

export const kaspaAmountToInt = (amount?: number) => {
  if (!amount) return 0
  return amount / 1e8
}

export const formatKaspaAmount = (amount?: number, precision: number = 8) => {
  if (!amount) return `0 ${UNIT_STRING}`

  return (
    kaspaAmountToInt(amount).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: precision,
    }) + ` ${UNIT_STRING}`
  )
}

export const formatIsoTime = (isoString: string, timeOnly = false) => {
  if (timeOnly) {
    return convertUtcToLocal(isoString).format("HH:mm:ss")
  }
  return convertUtcToLocal(isoString).format("YYYY-MM-DD HH:mm:ss")
}

export const formatUnixTime = (
  unixTime: number,
  timeOnly = false,
  format = "YYYY-MM-DD HH:mm:ss"
) => {
  if (timeOnly) {
    return moment.unix(unixTime).format("HH:mm:ss")
  }
  return moment.unix(unixTime).format(format)
}

export const convertUtcToLocal = (value: string) => {
  return moment(value)
}

export const shortenKaspaAddress = (address?: string, start = 6, end = 12) => {
  if (!address) return ""

  return (
    address.substring(start, end) +
    "..." +
    address.substring(address.length - 4)
  )
}

export const shortenKaspaAddressWithPrefix = (
  address: string,
  headCount: number,
  tailCount: number
) => {
  if (!address) return ""

  const seperatorIndex = address.indexOf(":")
  return (
    address.substring(0, seperatorIndex + 1 + headCount) +
    "..." +
    address.slice(-tailCount)
  )
}

export const getTimeDifference = (timestamp?: number, shorted = true) => {
  if (!timestamp) return ""

  const currentTime = Math.floor(Date.now())
  const timeDifference = Math.abs(currentTime - timestamp) / 1000
  const timeDiffText = timestamp > currentTime ? "" : "ago"

  const oneDay = 24 * 60 * 60
  const oneHour = 60 * 60
  const oneMinute = 60
  const getTimeUnit = (value: number, unit: string, shortUnit: string) => {
    const count = Math.floor(value)
    if (shorted) {
      return `${count}${shortUnit}`
    }
    const unitText = count === 1 ? unit : `${unit}s`
    return `${count} ${unitText} ${timeDiffText}`
  }

  if (timeDifference >= oneDay) {
    return getTimeUnit(timeDifference / oneDay, "day", "d")
  } else if (timeDifference >= oneHour) {
    return getTimeUnit(timeDifference / oneHour, "hour", "h")
  } else if (timeDifference >= oneMinute) {
    return getTimeUnit(timeDifference / oneMinute, "minute", "m")
  } else {
    return shorted ? "just now" : "less than a minute ago"
  }
}

export const getTimeCountdown = (timestamp?: number) => {
  if (!timestamp) return ""

  const currentTime = Math.floor(Date.now())
  let timeDifference = Math.abs(currentTime - timestamp) / 1000

  const days = Math.floor(timeDifference / (24 * 60 * 60))
  timeDifference -= days * 24 * 60 * 60
  const hours = Math.floor(timeDifference / (60 * 60))
  timeDifference -= hours * 60 * 60
  const minutes = Math.floor(timeDifference / 60)
  const seconds = timeDifference - minutes * 60

  if (days > 0) {
    const dayPart = `${days} day`
    const hourPart = days === 1 ? `${hours} hour` : ""
    return `${dayPart} ${hourPart}`
  } else if (hours > 0) {
    const hourPart = `${hours} hour`
    const minutePart = hours === 1 ? `${minutes} min` : ""
    return `${hourPart} ${minutePart}`
  } else if (minutes > 0) {
    const minutePart = `${minutes} min`
    const secondPart = minutes === 1 ? `${seconds} sec` : ""
    return `${minutePart} ${secondPart}`
  } else {
    return `${seconds} second`
  }
}

export const getLoadingValue = (
  val: string | undefined,
  placeholder?: string
) => {
  return val && val !== "" ? val : placeholder || ""
}

export const formatCash = (n: number, toFixed: number = 1) => {
  if (!n) return ""

  if (n < 1e3) return n.toString()
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(toFixed) + "K"
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(toFixed) + "M"
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(toFixed) + "B"
  if (n >= 1e12) return +(n / 1e12).toFixed(toFixed) + "T"
}

export const getTotalTransactionAmount = (
  outputs?: (TransactionOutput | KaspadOutputType)[]
) => {
  return (
    outputs?.reduce((acc, cur) => {
      const amount = "amount" in cur ? cur.amount : cur.value
      return acc + Number(amount)
    }, 0) || 0
  )
}

export const getRandomAdsPlatfrom = () => {
  const platforms: PlatformType[] = ["none"]
  return platforms[Math.floor(Math.random() * platforms.length)]
}

export const sompiToKaspa = (sompi: number) => {
  return sompi / 1e8
}

export const kaspaToSompi = (kaspa: number) => {
  return kaspa * 1e8
}

export const formatPercent = (n: number, precision: number = 2) => {
  const factor = Math.pow(10, precision)
  const truncatedNumber = (Math.floor(n * factor) / factor).toFixed(precision)

  const zeros = "." + "0".repeat(precision)

  return truncatedNumber.endsWith(zeros)
    ? Math.floor(n) + "%"
    : truncatedNumber + "%"
}

export const trackEvent = (eventName: string, props?: any) => {
  window.plausible && window.plausible(eventName, { props })
}
