/* eslint-disable jsx-a11y/anchor-is-valid */
import { Empty } from "antd"
import { useIntl } from "react-intl"

export const EmptyState = ({
  textId,
  actionButtonTextId,
  actionOnClick,
}: {
  textId: string
  actionButtonTextId?: string
  actionOnClick?: () => void
}) => {
  const intl = useIntl()
  return (
    <div className="mt-10 mb-10">
      <Empty description={intl.formatMessage({ id: textId })}>
        {actionButtonTextId && actionOnClick ? (
          <a
            href="#"
            onClick={actionOnClick}
            className="btn btn-secondary btn-md"
          >
            {intl.formatMessage({ id: actionButtonTextId })}
          </a>
        ) : null}
      </Empty>
    </div>
  )
}
