import { IntlShape } from "react-intl"
import { trackEvent } from "./utils"

export const formatTokenAmount = (
  ticker: string,
  amount: number,
  decimal: number,
  precision: number = 8
) => {
  if (!amount) return `0 ${ticker}`

  const amountAfterDecimal = (amount as number) / 10 ** (decimal as number)
  return (
    amountAfterDecimal.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: precision,
    }) + ` ${ticker}`
  )
}

export const getTokenStatus = (intl: IntlShape, status: string) => {
  switch (status) {
    case "deployed":
      return intl.formatMessage({ id: "DEPLOYED" })
    case "finished":
      return intl.formatMessage({ id: "FINISHED" })
    case "unused":
      return intl.formatMessage({ id: "UNUSED" })
    case "reserved":
      return intl.formatMessage({ id: "RESERVED" })
    case "ignored":
      return intl.formatMessage({ id: "IGNORED" })
  }
}

export const getTokenTransactionErrorStatus = (opError: string) => {
  return opError.charAt(0).toUpperCase() + opError.slice(1)
}

export const trackTokenAdsClickedEvent = (location: string) => {
  trackEvent("token-ads-clicked", { location })
}

export const trackTokenExchangeLinkClickedEvent = (
  ticker: string,
  exchange: string
) => {
  trackEvent("token-exchange-link-clicked", { ticker, exchange })
}
