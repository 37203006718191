import { useState, useEffect } from "react"
import { openDB, DBSchema, IDBPDatabase } from "idb"

interface TokenDB extends DBSchema {
  tokens: {
    key: string
    value: any
  }
}

const DB_NAME = "ApiCache"
const STORE_NAME = "tokens"
const DB_VERSION = 1

const initDB = async (): Promise<IDBPDatabase<TokenDB>> => {
  return openDB<TokenDB>(DB_NAME, DB_VERSION, {
    upgrade(db) {
      db.createObjectStore(STORE_NAME)
    },
  })
}

export const useIndexedDB = <T>(key: string) => {
  const [value, setValue] = useState<T | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const loadData = async () => {
      try {
        const db = await initDB()
        const data = await db.get(STORE_NAME, key)
        setValue((data as T) || null)
      } catch (err) {
        setError(err instanceof Error ? err : new Error("An error occurred"))
      } finally {
        setLoading(false)
      }
    }

    loadData()
  }, [key])

  const setData = async (newValue: T) => {
    try {
      const db = await initDB()
      await db.put(STORE_NAME, newValue, key)
      setValue(newValue)
    } catch (err) {
      setError(
        err instanceof Error
          ? err
          : new Error("An error occurred while setting data")
      )
    }
  }

  return { value, setValue: setData, loading, error }
}
