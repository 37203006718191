/* eslint-disable jsx-a11y/anchor-is-valid */

import CountUp from "react-countup"
import { Skeleton } from "antd"

type Props = {
  className: string
  color: string
  title: string
  description: string
  progress?: number
}

const ProgressMetricCard: React.FC<Props> = ({
  className,
  color,
  title,
  description,
  progress,
}) => {
  return (
    <div className={`card bg-light-${color} ${className} `}>
      <div className="card-body my-3">
        <div className={`card-title fw-bold text-${color} fs-5 mb-3 d-block`}>
          {description}
        </div>

        <div className="py-1">
          <span className="text-dark fs-1 fw-bold me-2">
            {progress ? (
              <CountUp preserveValue decimals={3} end={progress} suffix={"%"} />
            ) : (
              <Skeleton.Button active size={"small"} />
            )}
          </span>

          <span className="fw-semibold text-muted fs-7">{title}</span>
        </div>

        <div className={`progress h-7px bg-${color} bg-opacity-50 mt-7`}>
          <div
            className={`progress-bar bg-${color}`}
            role="progressbar"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
    </div>
  )
}

export { ProgressMetricCard }
