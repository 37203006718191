import { IntlShape } from "react-intl"
import { DashboardMetricsResponse } from "../../../client"
import {
  formatHashrate,
  formatSupplyNumber,
  formatUnixTime,
  getTimeCountdown,
  getTimeDifference,
} from "../../utils/utils"
import { UNIT_STRING } from "../../constants/constants"

export interface DashboardMetricCardProps {
  key: string
  svgIcon: string
  title: string | JSX.Element | number | undefined
  titleSuffix?: string
  titleClass?: string
  description: string
  decimals?: number
}

type RendererType = (key: string) => string | JSX.Element | undefined

export const availableDashboardMetricCards = ({
  intl,
  data,
  renderer,
}: {
  intl: IntlShape
  data?: DashboardMetricsResponse
  renderer?: RendererType
}): DashboardMetricCardProps[] => {
  const metrics: DashboardMetricCardProps[] = [
    {
      svgIcon: "/media/icons/duotune/general/gen004.svg",
      title: data?.daaScore ? Number(data?.daaScore) : undefined,
      description: intl.formatMessage({ id: "DAA_SCORE" }),
      key: "daaScore",
    },
    {
      svgIcon: "/media/icons/duotune/finance/fin007.svg",
      title: formatSupplyNumber(Number(data?.currentSupply) / 1e8)?.value,
      titleSuffix: formatSupplyNumber(Number(data?.currentSupply) / 1e8)?.unit,
      description: intl.formatMessage({ id: "CURRENT_SUPPLY" }),
      decimals: 4,
      key: "currentSupply",
    },
    {
      svgIcon: "/media/icons/duotune/general/gen002.svg",
      title: data?.tps,
      titleSuffix: "TPS",
      description: intl.formatMessage({ id: "REAL_TIME_TPS" }),
      key: "tps",
      decimals: 1,
    },
    {
      svgIcon: "/media/icons/duotune/general/gen016.svg",
      title: data?.bps,
      titleSuffix: "BPS",
      description: intl.formatMessage({ id: "REAL_TIME_BPS" }),
      key: "bps",
      decimals: 1,
    },
    {
      svgIcon: "/media/icons/duotune/coding/cod009.svg",
      title: formatHashrate(data?.hashrate).value,
      titleSuffix: formatHashrate(data?.hashrate).unit,
      description: intl.formatMessage({ id: "HASHRATE" }),
      key: "hashrate",
      decimals: 2,
    },
    {
      svgIcon: "/media/icons/duotune/coding/cod005.svg",
      title: data?.minedPct,
      titleSuffix: "%",
      description: intl.formatMessage({ id: "MINED_P" }),
      key: "mined_pct",
      decimals: 2,
    },
    {
      svgIcon: "/media/icons/duotune/general/gen014.svg",
      title: getTimeCountdown(data?.nextHalvingTimestamp),
      description: intl.formatMessage({ id: "NEXT_HALVING" }),
      key: "next_halving_timestamp",
    },
    {
      svgIcon: "/media/icons/duotune/finance/fin010.svg",
      title: data?.nextHalvingReward,
      titleSuffix: UNIT_STRING,
      description: intl.formatMessage({ id: "NEXT_REWARD" }),
      key: "next_halving_reward",
    },
    {
      svgIcon: "/media/icons/duotune/finance/fin010.svg",
      title: data?.currentReward,
      titleSuffix: UNIT_STRING,
      description: intl.formatMessage({ id: "CURRENT_REWARD" }),
      key: "current_reward",
    },
    {
      svgIcon: "/media/icons/duotune/finance/fin008.svg",
      title: data?.currentAddressCount,
      description: intl.formatMessage({
        id: "ACTIVE_ADDRESSES",
      }),
      key: "current_addresses_count",
    },
    {
      svgIcon: "/media/icons/duotune/general/gen020.svg",
      title: formatHashrate(data?.maxHashrate).value,
      titleSuffix: formatHashrate(data?.maxHashrate).unit,
      description: intl.formatMessage({ id: "HIGHEST_HASHRATE" }),
      key: "max_hashrate",
      decimals: 2,
    },
    {
      svgIcon: "/media/icons/duotune/general/gen020.svg",
      title: data?.maxTps,
      titleSuffix: "TPS",
      description: intl.formatMessage({ id: "MAX_TPS" }),
      key: "max_tps",
    },
    {
      svgIcon: "/media/icons/duotune/general/gen014.svg",
      title:
        data?.nextHalvingTimestamp &&
        formatUnixTime(data?.nextHalvingTimestamp / 1e3, false, "MM/DD HH:mm"),
      description: intl.formatMessage({ id: "NEXT_HALVING" }),
      key: "next_halving_exact_time",
    },
  ]

  metrics.forEach((metric) => {
    const child = renderer && renderer(metric.key)
    if (child) {
      metric.title = child
    }
  })

  return metrics
}
