import { useIntl } from "react-intl"

const STATUS_INTL_PREFIX = "TRANSACTION.STATUS"

export const TokenOpStatusTag = ({
  accepted,
  error,
  className = "mb-2",
}: {
  accepted?: boolean
  error?: string
  className?: string
}) => {
  const intl = useIntl()

  return accepted ? (
    <span className={`badge badge-light-success ${className}`}>
      {intl.formatMessage({ id: `${STATUS_INTL_PREFIX}.ACCEPTED` })}
    </span>
  ) : (
    <span className={`badge badge-light-danger ${className}`}>
      {intl.formatMessage({ id: error })}
    </span>
  )
}
