/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Transaction } from '../models/Transaction';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TransactionsService {

    /**
     * Return details for a Kaspa transaction
     * @param id ID of a Kaspa transaction
     * @returns Transaction
     * @throws ApiError
     */
    public static transactionsControllerGetTransaction(
        id: string,
    ): CancelablePromise<Transaction> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/transactions/{id}',
            path: {
                'id': id,
            },
        });
    }

}
