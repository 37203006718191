import { useRequest } from "ahooks"
import { useIntl } from "react-intl"
import { AnalyticsService } from "../../../client"
import { MetricCard } from "../dashboard/components/MetricCard"
import { ProgressMetricCard } from "../dashboard/components/ProgressMetricCard"

const metricsCardClass = `col-xl-3 mb-5 mb-xl-0`

const HoldersMetricsSection = () => {
  const intl = useIntl()
  const { data, loading } = useRequest(
    AnalyticsService.analyticsControllerGetAddressesOverview
  )

  return (
    <>
      <div className={metricsCardClass}>
        <MetricCard
          className="bg-body card-xl-stretch mb-xl-8"
          svgIcon={"/media/icons/duotune/finance/fin008.svg"}
          titleClass="text-dark fw-bold fs-2 mb-2 mt-5"
          descriptionClass="text-muted"
          iconClass="svg-icon-primary"
          title={data?.holdersCount}
          description={intl.formatMessage({
            id: "HOLDERS.METRICS.ACTIVE_ADDRESSES.TITLE",
          })}
        />
      </div>

      <div className={metricsCardClass}>
        <ProgressMetricCard
          className="card-xl-stretch mb-xl-8"
          color="success"
          title={intl.formatMessage({
            id: "HOLDERS.METRICS.TOP_HOLD.OF_TOTAL_SUPPLY",
          })}
          description={intl.formatMessage(
            {
              id: "HOLDERS.METRICS.TOP_X_HOLD.TITLE",
            },
            { x: 10 }
          )}
          progress={data?.top10HoldersPct}
        />
      </div>

      <div className={metricsCardClass}>
        <ProgressMetricCard
          className="card-xl-stretch mb-xl-8"
          color="success"
          title={intl.formatMessage({
            id: "HOLDERS.METRICS.TOP_HOLD.OF_TOTAL_SUPPLY",
          })}
          description={intl.formatMessage(
            {
              id: "HOLDERS.METRICS.TOP_X_HOLD.TITLE",
            },
            { x: 100 }
          )}
          progress={data?.top100HoldersPct}
        />
      </div>

      <div className={metricsCardClass}>
        <ProgressMetricCard
          className="card-xl-stretch mb-xl-8"
          color="success"
          title={intl.formatMessage({
            id: "HOLDERS.METRICS.TOP_HOLD.OF_TOTAL_SUPPLY",
          })}
          description={intl.formatMessage(
            {
              id: "HOLDERS.METRICS.TOP_X_HOLD.TITLE",
            },
            { x: 1000 }
          )}
          progress={data?.top1000HoldersPct}
        />
      </div>
    </>
  )
}

export { HoldersMetricsSection }
