/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import { Input, Table, TableColumnsType } from "antd"
import { useSize } from "ahooks"
import { SearchOutlined } from "@ant-design/icons"
import { GetAddressTokensResponse } from "../../../../client"
import {
  formatTokenAmount,
  trackTokenAdsClickedEvent,
} from "../../../utils/tokenUtils"
import { getTokenRoute } from "../../../hooks/navigator"
import { useIntl } from "react-intl"
import { TokenIcon } from "../../../components/partials/TokenIcon"
import { FiatAmountBadge } from "../../../components/FiatAmountBadge"
import { KSPR_AFFILIATE_URL } from "../../../constants/constants"
import { Link } from "react-router-dom"

interface AddressTokensListProps {
  tokens: GetAddressTokensResponse[]
}

const TokenRow = ({ token }: { token: GetAddressTokensResponse }) => {
  return (
    <div className="p-1" role="button">
      <div className="d-flex align-items-center">
        <TokenIcon url={token.iconUrl} className="me-2" size="20px" />
        <div>
          <div className="d-flex align-items-center text-body fs-6 fw-bold gap-1">
            <span>{token.ticker}</span>
          </div>
        </div>
      </div>
      <div className="fs-6 mt-1 d-flex align-items-center">
        <span className="text-body fw-bold">
          {formatTokenAmount(token.ticker, token.balance, token.decimal)}
        </span>
        {token.price?.floorPrice ? (
          <FiatAmountBadge
            kaspaAmount={
              (token.balance / Math.pow(10, token.decimal)) *
              token.price?.floorPrice
            }
            precision={2}
          />
        ) : null}
      </div>
    </div>
  )
}

const columns = (): TableColumnsType<GetAddressTokensResponse> => {
  return [
    {
      render: (token: GetAddressTokensResponse) => {
        return (
          <Link to={getTokenRoute(token.ticker)}>
            <TokenRow token={token} />
          </Link>
        )
      },
    },
  ]
}

const AddressTokensList: React.FC<AddressTokensListProps> = ({ tokens }) => {
  const intl = useIntl()

  const size = useSize(document.body)
  const divWidth = size?.width && size?.width > 400 ? 400 : 300

  const [searchText, setSearchText] = React.useState("")

  return (
    <div className={`w-${divWidth}px`}>
      <div className="p-3">
        <Input
          placeholder={intl.formatMessage({ id: "SEARCH_FOR_TOKEN_NAME" })}
          prefix={<SearchOutlined />}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      <Table
        columns={columns()}
        dataSource={tokens.filter((token) =>
          token.ticker.toLowerCase().includes(searchText.toLowerCase())
        )}
        size="small"
        showHeader={false}
        tableLayout="auto"
        scroll={{ y: 400 }}
        pagination={false}
      />
    </div>
  )
}

export { AddressTokensList }
